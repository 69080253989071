export let propertyObject={
    title: '',
    description: '',
    discountPrice: '',
    price: '',
    floor: '',
    area: '',
    amenities: [],
    images: [],
    serverImages: [], // Ensure this is an array to avoid the error

    images360: '',
    threeDModels: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    latitude: '',
    longitude: '',
    numberOfBedrooms: '',
    numberOfBathrooms: '',
    constructionYear: '',
    agentName: '',
    agentPhone: '',
    agentEmail: '',
    nearbyPlaces: [],
    availableQuantity: "",
    totalQuantity: "",
}