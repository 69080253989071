import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ApiService from "../../../config/api"; // Adjust the path as per your file structure
import { propertyObject } from "../../../models/property";
import { validatePropertyForm } from "../../../models/propertyValidation"; // Adjust the path as needed
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const EditPropertyForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [property, setProperty] = useState(propertyObject);
  const [errorMessages, setErrorMessages] = useState({});

  const [nearbyPlace, setNearbyPlace] = useState("");
  const [amenity, setAmenity] = useState("");

  const [imagePreviews, setImagePreviews] = useState([]);
  //   const [serverImages, setServerImages] = useState([]);
  const { id } = useParams();

  const [imageGet, setImageGet] = useState([]);
  const predefinedNearbyPlaces = [
    "Shopping Mall",
    "Hospital",
    "School",
    "Public Transport",
    "Restaurant",
    "Bank",
    "Supermarket",
    "Park",
    "Gym",
    "Cinema",
  ];
  useEffect(() => {
    if (id) {
      console.log("Editing mode, Property ID:", id);
      fetchPropertyDetails(id);
    }
  }, [id]);

  const fetchPropertyDetails = async (id) => {
    try {
      const successResponse = await ApiService.get(`admin/property/${id}`);
      const {
        meta: { property },
      } = successResponse.data;
      setProperty(property);
      //   setImagePreviews(property.images);
      //   setServerImages(property.images);
      setImageGet(property.images);
    } catch (error) {
      console.error("Error fetching property details:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProperty({
      ...property,
      [name]: value,
    });
  };

  //

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => file); // Store the new image files
    const newPreviews = files.map((file) => URL.createObjectURL(file)); // Generate previews

    setProperty((prevProperty) => ({
      ...prevProperty,
      images: [...newImages], // Store only new images, not mixing with server images
    }));
    setImagePreviews([...newPreviews]); // Only store previews for new images
  };

  // Handle removing a server image
  const handleRemoveServerImage = (image) => {
    // setServerImages((prevImages) => prevImages.filter((img) => img !== image));
    setImageGet((prevImages) => prevImages.filter((img) => img !== image));
  };

  // Handle removing a new uploaded image preview
  const handleRemoveNewImage = (index) => {
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index)
    );
    setProperty((prevProperty) => ({
      ...prevProperty,
      images: prevProperty.images.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveAmenity = (index) => {
    setProperty((prevProperty) => ({
      ...prevProperty,
      amenities: prevProperty.amenities.filter((_, i) => i !== index),
    }));
  };

  const handleRemovePlace = (index) => {
    setProperty((prevProperty) => {
      const updatedPlaces = [...prevProperty.nearbyPlaces];
      updatedPlaces.splice(index, 1);
      return { ...prevProperty, nearbyPlaces: updatedPlaces };
    });
  };

  // const handleSubmit = async (e) => {
  //     e.preventDefault();

  //     // Validate the property form (adjust according to your form validation logic)
  //     const { isValid, errors } = validatePropertyForm(property);
  //     if (!isValid) {
  //         setErrorMessages(errors);
  //         return;
  //     }

  //     // Create a FormData object to handle form submission
  //     const formData = new FormData();

  //     // Add other form fields to formData, except for images
  //     Object.keys(property).forEach((key) => {
  //         if (key !== 'images') {
  //             formData.append(key, property[key]);
  //         }
  //     });

  //     // Ensure serverImages and images arrays are initialized
  //     const serverImages = Array.isArray(property.serverImages) ? property.serverImages : [];
  //     const newImages = Array.isArray(property.images) ? property.images : [];

  //     // Append existing image URLs and new image files under the same 'images' field
  //     serverImages.forEach((imageUrl) => {
  //         if (typeof imageUrl === 'string') {
  //             formData.append('images', imageUrl); // Append existing image URLs
  //         }
  //     });

  //     newImages.forEach((image) => {
  //         if (image instanceof File) {
  //             formData.append('images', image); // Append new image files
  //         }
  //     });

  //     // API call to update property with images
  //     try {
  //         const response = await ApiService.put(`admin/update-property/${id}`, formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data',
  //             },
  //         });

  //         // Handle success and display a success message
  //         console.log('Images updated successfully:', response.data);
  //         toast.success(response.data.message);

  //     } catch (error) {
  //         // Handle error and display an error message
  //         console.error('Error updating images:', error);
  //         toast.error('Error updating images');
  //     }
  // };
  async function urlToFile(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileType = blob.type;
    return new File([blob], filename, { type: fileType });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the property form (adjust according to your form validation logic)
    const { isValid, errors } = validatePropertyForm(property);
    if (!isValid) {
      setErrorMessages(errors);
      return;
    }

    // Create a FormData object to handle form submission
    const formData = new FormData();

    // Add other form fields to formData, except for images and serverImages
    Object.keys(property).forEach((key) => {
      if (key !== "images" && key !== "serverImages") {
        // Handle amenities separately to append as indexed entries
        if (key === "amenities" && Array.isArray(property.amenities)) {
          property.amenities.forEach((amenity, index) => {
            formData.append(`amenities[${index}]`, amenity);
          });
        } else if (
          key === "nearbyPlaces" &&
          Array.isArray(property.nearbyPlaces)
        ) {
          property.nearbyPlaces.forEach((place, index) => {
            formData.append(`nearbyPlaces[${index}]`, place);
          });
        } else {
          formData.append(key, property[key]);
        }
      }
    });

    // Ensure serverImages and images arrays are initialized
    const serverImages = Array.isArray(imageGet) ? imageGet : [];
    const newImages = Array.isArray(property.images) ? property.images : [];
    console.log(serverImages);

    // Loop through new images (that are Files) and append to formData
    newImages.forEach((image) => {
      if (image instanceof File) {
        formData.append("images", image);
      }
    });

    // Loop through server images (URLs) and append to formData
    serverImages.forEach((image) => {
      if (typeof image === "string") {
        formData.append("imageUrls", image);
      }
    });

    // API call to update property with images
    try {
      const response = await ApiService.put(
        `admin/update-property/${id}`,
        formData
      );

      // Handle success and display a success message
      console.log("Property updated successfully:", response.data);
      toast.success(response.data.message);
    } catch (error) {
      // Handle error and display an error message
      console.error("Error updating property:", error);
      toast.error("Error updating property");
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Sidebar
        open={sidebarOpen}
        toggleDrawer={toggleDrawer}
        setOpen={setSidebarOpen}
      />

      <div className="property-cont">
        <div className="card rounded  card-add-property g-2">
          <div
            className="px-3 py-3"
            style={{ borderBottom: "1px solid var(--bs-secondary)" }}
          >
            <h5 className="mb-0 p-2 ">Edit Property</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    placeholder="Enter Title"
                    className={`customInput form-control ${
                      errorMessages.title ? "is-invalid" : ""
                    }`}
                    type="text"
                    id="title"
                    name="title"
                    value={property.title}
                    onChange={handleChange}
                  />
                  {errorMessages.title && (
                    <p className="error-text">{errorMessages.title}</p>
                  )}
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    rows="3"
                    value={property.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="discountPrice" className="form-label">
                    Discount Price
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="discountPrice"
                    name="discountPrice"
                    value={property.discountPrice}
                    onChange={handleChange}
                  />
                </div>
                
                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="price" className="form-label">
                    Price
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="price"
                    name="price"
                    value={property.price}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="totalQuantity" className="form-label">
                    Total Quantity
                  </label>
                  <input
                    className={`customInput form-control ${
                      errorMessages.latitude ? "is-invalid" : ""
                    }`}
                    type="number"
                    id="totalQuantity"
                    placeholder="Enter total Quantity"
                    name="totalQuantity"
                    value={property.totalQuantity}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="availableQuantity" className="form-label">
                    Available Quantity
                  </label>
                  <input
                    className={`customInput form-control ${
                      errorMessages.latitude ? "is-invalid" : ""
                    }`}
                    type="number"
                    id="availableQuantity"
                    placeholder="Enter available Quantity"
                    name="availableQuantity"
                    value={property.availableQuantity}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="floor" className="form-label">
                    Floor
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="floor"
                    name="floor"
                    value={property.floor}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="area" className="form-label">
                    Area
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="area"
                    name="area"
                    value={property.area}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    className="customInput form-control"
                    type="text"
                    id="city"
                    name="city"
                    value={property.city}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    className="customInput form-control"
                    type="text"
                    id="state"
                    name="state"
                    value={property.state}
                    onChange={handleChange}
                  />
                </div>
                
                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    className="customInput form-control"
                    type="text"
                    id="country"
                    name="country"
                    value={property.country}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="pincode" className="form-label">
                    Pincode
                  </label>
                  <input
                    className="customInput form-control"
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={property.pincode}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="latitude" className="form-label">
                    Latitude
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="latitude"
                    name="latitude"
                    value={property.latitude}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="longitude" className="form-label">
                    Longitude
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="longitude"
                    name="longitude"
                    value={property.longitude}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="numberOfBedrooms" className="form-label">
                    Number of Bedrooms
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="numberOfBedrooms"
                    name="numberOfBedrooms"
                    value={property.numberOfBedrooms}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="numberOfBathrooms" className="form-label">
                    Number of Bathrooms
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="numberOfBathrooms"
                    name="numberOfBathrooms"
                    value={property.numberOfBathrooms}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="constructionYear" className="form-label">
                    Construction Year
                  </label>
                  <input
                    className="customInput form-control"
                    type="text"
                    id="constructionYear"
                    name="constructionYear"
                    value={property.constructionYear}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="agentName" className="form-label">
                    Agent Name
                  </label>
                  <input
                    className="customInput form-control"
                    type="text"
                    id="agentName"
                    name="agentName"
                    value={property.agentName}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="agentPhone" className="form-label">
                    Agent Phone
                  </label>
                  <input
                    className="customInput form-control"
                    type="number"
                    id="agentPhone"
                    name="agentPhone"
                    value={property.agentPhone}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="agentEmail" className="form-label">
                    Agent Email
                  </label>
                  <input
                    className="customInput form-control"
                    type="email"
                    id="agentEmail"
                    name="agentEmail"
                    value={property.agentEmail}
                    onChange={handleChange}
                  />
                </div>

                {/* AMENITY */}
                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label htmlFor="amenity" className="form-label">
                    Add Amenity
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={amenity}
                      onChange={(e) => setAmenity(e.target.value)}
                    />
                    <button
                      className="btn btn-add btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        // Split input by commas, trim spaces, and filter out empty strings
                        const amenitiesToAdd = amenity
                          .split(",")
                          .map((item) => item.trim())
                          .filter((item) => item); // Ensure no empty strings are added

                        // Log amenities being added
                        console.log("Amenities being added:", amenitiesToAdd);

                        // Log existing amenities
                        console.log(
                          "Existing amenities before adding:",
                          property.amenities
                        );

                        // Add new amenities to the property state
                        setProperty((prevProperty) => {
                          const updatedAmenities = [
                            ...prevProperty.amenities,
                            ...amenitiesToAdd,
                          ];

                          // Log updated amenities after adding
                          console.log("Updated amenities:", updatedAmenities);

                          return {
                            ...prevProperty,
                            amenities: updatedAmenities,
                          };
                        });

                        setAmenity(""); // Clear input after adding
                      }}
                    >
                      Add
                    </button>
                  </div>

                  <ul>
                    {property.amenities.map((amenityItem, index) => (
                      <li key={index} style={{ color: "var(--bs-accent)" }}>
                        {amenityItem}
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveAmenity(index)}
                          aria-label="remove"
                          style={{ marginLeft: "10px" }}
                        >
                          <CloseIcon
                            style={{
                              color: "var(--bs-accent)",
                              fontSize: "17px",
                            }}
                          />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* AMENITY end */}

                {/* PLACES START */}
                <div className="col-md-4 col-sm-6 px-4 mb-3 list-color">
                  <label htmlFor="nearbyPlace" className="form-label">
                    Add Nearby Place
                  </label>

                  {/* Input field for both typing and dropdown selection */}
                  <div className="input-group mb-3">
                    <input
                      list="placesList"
                      className="form-control"
                      placeholder="Type or select nearby places"
                      value={nearbyPlace}
                      onChange={(e) => setNearbyPlace(e.target.value)}
                    />

                    {/* Dropdown for predefined nearby places */}
                    <datalist id="placesList">
                      {predefinedNearbyPlaces.map((placeOption, index) => (
                        <option key={index} value={placeOption} />
                      ))}
                    </datalist>

                    {/* Button to add selected or custom nearby places */}
                    <button
                      className="btn btn-add btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        const placesList = nearbyPlace
                          .split(",")
                          .map((item) => item.trim()); // Allow multiple entries separated by commas
                        const newPlaces = placesList.filter(
                          (item) =>
                            item && !property.nearbyPlaces.includes(item)
                        );

                        if (newPlaces.length) {
                          setProperty((prevProperty) => ({
                            ...prevProperty,
                            nearbyPlaces: [
                              ...prevProperty.nearbyPlaces,
                              ...newPlaces,
                            ],
                          }));
                          setNearbyPlace(""); // Clear input field after adding
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>

                  {/* Display added nearby places */}
                  <ul>
                    {property.nearbyPlaces.map((placeItem, index) => (
                      <li key={index}>
                        {placeItem}
                        <IconButton
                          size="small"
                          onClick={() => handleRemovePlace(index)}
                          aria-label="remove"
                          style={{ marginLeft: "10px" }}
                        >
                          <CloseIcon
                            style={{
                              color: "var(--bs-accent)",
                              fontSize: "17px",
                            }}
                          />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* PLACES END */}

                <div className="col-md-4 col-sm-6 px-4 mb-3">
                  <label className="form-label">Upload Images</label>
                  <input
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />

                  <div className="mt-2">
                    {/* Display server images */}
                    {imageGet.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={image}
                          alt={`Server Image ${index}`}
                          style={{ maxWidth: "100px" }}
                        />
                        <p
                          type="button"
                          // style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleRemoveServerImage(image)}
                          className="clear-img"
                        >
                          x
                        </p>
                      </div>
                    ))}

                    {/* Display newly uploaded images (previews) */}
                    {imagePreviews.map((preview, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={preview}
                          alt={`New Image Preview ${index}`}
                          style={{ maxWidth: "100px" }}
                        />
                        <button
                          type="button"
                          style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleRemoveNewImage(index)}
                        >
                          &#10005;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/*  */}
              </div>
              <button type="submit" className="btn btn-accent">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Box>
  );
};

export default EditPropertyForm;
