import { LOGIN, REGISTER } from "../config/api";
import axios from "axios";

export const loginService = async (email, password) => {
  try {
    const response = await axios.post(LOGIN, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Login failed");
  }
};

export const registerService = async (payload) => {
    try {
        const response = await axios.post(REGISTER, payload);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return { error: true, message: error.response.data.message || error.message };
        } else {
            return { error: true, message: error.message };
        }
    }
};
