import React from "react";
import "./Contact.css";
import "../../../index.css";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const Contact = () => {
  return (
    <>
      <div style={{ backgroundColor: 'var(--bs-secondary)', color:'var(--bs-primary)' }}>
        <Navbar />
        <div className="contact-section-1 mt-5 " style={{ backgroundColor: 'var(--bs-accent)' }}>
          <div className="background-image  mb-10">
            <div className="container">
              <div className="contactus text-light align-items-center justify-content-center">
                <h1
                  className="display-4 headingone mt-5"
                  style={{ color: 'var(--bs-accent)' }}
                >
                  Contact Us
                </h1>
                <p className="para1" style={{ color: 'var(--bs-accent)' }}>
                  Quickly aggregate B2B users and worldwide potentialities.
                  Progressively plagiarize resource-leveling e-commerce through
                  resource-leveling core competencies. Dramatically mesh low-risk
                  high-yield alignments before transparent e-tailers.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="container mt-5">
          <div className="row">
            {/* Our Location Section */}
            <h1
              style={{
                textAlign: "center",
                margin: "25px 0px 25px 0px",
                fontFamily: "Bahnschrift regular",
              }}
            >
              Contact Details
            </h1>
            {/*   Address Section */}
            <div className="col-md-4 col-12 mb-3 text-center text-md-start">
              <h3 style={{ textAlign: "center" }}>Address</h3>
              <p className="mb-1" style={{ textAlign: "center" }}>
                26, Street No. 1
              </p>
              <p style={{ textAlign: "center" }}>koh e fiza Bhopal, 462001</p>
            </div>

            {/* Email Section */}
            <div className="col-md-4 col-12 mb-3 text-center text-md-start">
              <h3 style={{ textAlign: "center" }}> Email</h3>
              <p className="mb-1" style={{ textAlign: "center" }}>
                companymail@gmail.com
              </p>
              <p style={{ textAlign: "center" }}>www.eleganza.co.in</p>
            </div>

            {/* Call Us Section */}
            <div className="col-md-4 col-12 mb-3 text-center text-md-start">
              <h3 style={{ textAlign: "center" }}>Call Us</h3>
              <p className="mb-1" style={{ textAlign: "center" }}>
                +91 9630998899
              </p>
              <p className="mb-1" style={{ textAlign: "center" }}>
                +91 9993338877
              </p>
            </div>
            {/*  */}
            {/*  */}
          </div>
        </div>

        {/*  */}
        <div className="container d-flex justify-content-center align-items-center min-vh-100 mb-2 mt-4 text-center">
          <div
            className="row border p-3  shadow box-area"
            style={{ maxWidth: "1000px", borderRadius: "10px", backgroundColor:'var(--bs-accent)' }}
          >
            <div
              className="col-md-6 d-flex justify-content-center align-items-center flex-column left-box"
              style={{
                backgroundColor: "var(--bs-primary)",
                borderRadius: "10px",
              }}
            >
              <div className="featured-image mb-3 ">
                <img
                  src="./assets/images/logo/logoWithTagline.png"
                  className="img-fluid"
                  style={{ width: "300px" }}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-md-6 right-box ">
              <div className="header-text mb-4 contant-center">
                <h2 style={{ fontFamily: "Bahnschrift regular" }}>
                  Get in touch
                </h2>
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control form-control-lg bg-light fs-6"
                  placeholder="Full Name"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control form-control-lg bg-light fs-6"
                  placeholder="Email"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control form-control-lg bg-light fs-6"
                  placeholder="Subject"
                />
              </div>
              <div className="input-group mb-3">
                <textarea
                  type="text"
                  className="form-control form-control-lg bg-light fs-6"
                  placeholder="Message"
                />
              </div>
              <div className="input-group mb-3 d-flex justify-content-center">
                <button
                  className="btn btn-lg w-50 fs-6 "
                  style={{
                    backgroundColor: "var(--bs-primary)",
                    color: "var(--bs-accent)",
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      <Footer />
      </div>
    </>
  );
};

export default Contact;