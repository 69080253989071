// import React, { useState, useEffect } from "react";
// import { Table, Button, Form, InputGroup, Modal } from "react-bootstrap";
// import apiService from "../../../config/api"; // Import your API service
// import { useMediaQuery, useTheme, Box } from "@mui/material";
// import Sidebar from "../components/Sidebar";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useNavigate } from "react-router-dom";
// import AdminNavbar from "../components/AdminNavbar";
// import "./AllEmployees.css";
// const AllEmployees = () => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
//   const toggleDrawer = () => {
//     setSidebarOpen(!sidebarOpen);
//   };

//   const [employees, setEmployees] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
//   const [searchPerformed, setSearchPerformed] = useState(false);
//   const [noDataFound, setNoDataFound] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchEmployees();
//   }, []);

//   const fetchEmployees = async () => {
//     try {
//       const response = await apiService.get("/admin/employees");
//       setEmployees(response.data);
//       setNoDataFound(false);
//       setSearchPerformed(false);
//     } catch (error) {
//       console.error("Failed to fetch employees", error);
//     }
//   };

//   const handleSearch = async () => {
//     if (searchTerm.trim() === "") return;

//     try {
//       const response = await apiService.get(
//         `/admin/search-employee?name=${searchTerm}`
//       );
//       if (response.data.users.length === 0) {
//         setNoDataFound(true);
//         setEmployees([]);
//       } else {
//         setEmployees(response.data.users);
//         setNoDataFound(false);
//       }
//       setSearchPerformed(true);
//     } catch (error) {
//       console.error("Search failed", error);
//       setNoDataFound(true);
//     }
//   };

//   const handleClearSearch = async () => {
//     setSearchTerm("");
//     setSearchPerformed(false);
//     setNoDataFound(false);
//     fetchEmployees();
//   };

//   const handleEdit = (employeeId) => {
//     navigate(`/admin/addEmployForm/${employeeId}`);
//   };

//   const handleDelete = async () => {
//     try {
//       await apiService.delete(`/admin/delete-employee/${deleteEmployeeId}`);
//       setShowDeleteModal(false);
//       fetchEmployees();
//     } catch (error) {
//       console.error("Delete failed", error);
//     }
//   };

//   const openDeleteModal = (employeeId) => {
//     setDeleteEmployeeId(employeeId);
//     setShowDeleteModal(true);
//   };

//   const closeDeleteModal = () => {
//     setShowDeleteModal(false);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       handleSearch();
//     }
//   };

//   return (
//     <Box
//       display="grid"
//       gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
//       sx={{
//         transition: "grid-template-columns 0.3s ease-in-out",
//         overflow: "hidden",
//       }}
//     >
//       <Sidebar
//         open={sidebarOpen}
//         toggleDrawer={toggleDrawer}
//         setOpen={setSidebarOpen}
//       />
//       <div>
//         <AdminNavbar />
//         <div className="container mt-4">
//           <h2>Employee List</h2>

//           {/* Search Bar */}
//           <InputGroup className="mb-3 position-relative">
//             <Form.Control
//               type="text"
//               placeholder="Search employees"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               onKeyDown={handleKeyDown} // Search on Enter key
//             />
//             <Button variant="primary" onClick={handleSearch}>
//               Search
//             </Button>
//             <button onClick={handleClearSearch} className="ms-2 btn btn-accent">
//               Clear
//             </button>
//           </InputGroup>

//           {searchPerformed && (
//             <p>
//               Search results for: <strong>{searchTerm}</strong>
//             </p>
//           )}

//           {noDataFound && (
//             <p className="text-danger">
//               No employees found for the search term:{" "}
//               <strong>{searchTerm}</strong>
//             </p>
//           )}

//           <div className="mb-2">
//             <button
//               className="btn btn-accent"
//               onClick={() => navigate(`/admin/addEmployForm`)}
//             >
//               Add Agent
//             </button>
//           </div>

//           {/* Employee Table */}
//           <div className="responsive-table">
//           <div className="" style={{ overflowX: 'auto', width: '100%' }}>
//             <Table striped bordered hover className="text-center">
//               <thead>
//                 <tr className="aestyle">
//                   <th
//                     style={{
//                       color: "var(--bs-accent) !important",
//                       backgroundColor: "var(--bs-primary)!important",
//                       borderColor: "var(--bs-primary)!important",
//                     }}
//                   >
//                     S no
//                   </th>
//                   <th
//                     style={{
//                       color: "var(--bs-accent) !important",
//                       backgroundColor: "var(--bs-primary)!important",
//                       borderColor: "var(--bs-primary)!important",
//                     }}
//                   >
//                     Name
//                   </th>
//                   <th
//                     style={{
//                       color: "var(--bs-accent) !important",
//                       backgroundColor: "var(--bs-primary)!important",
//                       borderColor: "var(--bs-primary)!important",
//                     }}
//                   >
//                     Email
//                   </th>
//                   <th
//                     style={{
//                       color: "var(--bs-accent) !important",
//                       backgroundColor: "var(--bs-primary)!important",
//                       borderColor: "var(--bs-primary)!important",
//                     }}
//                   >
//                     Verified
//                   </th>
//                   <th
//                     style={{
//                       color: "var(--bs-accent) !important",
//                       backgroundColor: "var(--bs-primary)!important",
//                       borderColor: "var(--bs-primary)!important",
//                     }}
//                   >
//                     Actions
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {employees &&
//                   employees.map &&
//                   employees.map((employee, index) => (
//                     <tr key={employee.id} className="aestyle">
//                       <td className="aestyle">{index + 1}</td>
//                       <td className="aestyle">
//                         {employee.firstName} {employee.lastName}
//                       </td>
//                       <td className="aestyle">{employee.email}</td>
//                       <td className="aestyle">
//                         <span
//                           style={{
//                             backgroundColor: employee.verified ? "var(--bs-primary)" : "var(--bs-accent)",
//                             color: employee.verified ? "var(--bs-accent)" : "var(--bs-primary)",

//                             padding: "2px 6px",
//                             borderRadius: "4px",
//                             fontSize: "0.8em",
//                             width: "80px",
//                             display: "inline-block",
//                             textAlign: "center",
//                           }}
//                         >
//                           {employee.verified ? "Verified" : "Unverified"}
//                         </span>
//                       </td>
//                       <td className="aestyle">
//                         <EditIcon
//                           style={{
//                             color: "var(--bs-primary)",
//                             cursor: "pointer",
//                             marginRight: "10px",
//                           }}
//                           onClick={() => handleEdit(employee._id)}
//                         />
//                         <DeleteIcon
//                           style={{ color: "red", cursor: "pointer" }}
//                           onClick={() => openDeleteModal(employee._id)}
//                         />
//                       </td>
//                     </tr>
//                   ))}
//               </tbody>
//             </Table>
//             </div>
//             </div>
//             {/* Delete Confirmation Modal */}
//           <Modal show={showDeleteModal} onHide={closeDeleteModal}>
//             <Modal.Header closeButton>
//               <Modal.Title>Confirm Deletion</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               Are you sure you want to delete this employee?
//             </Modal.Body>
//             <Modal.Footer>
//               <Button variant="secondary" onClick={closeDeleteModal}>
//                 Cancel
//               </Button>
//               <Button variant="danger" onClick={handleDelete}>
//                 Delete
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </div>
//       </div>
//     </Box>
//   );
// };

// export default AllEmployees;















////////////////////////////

import React, { useState, useEffect } from "react";
import { Table, Button, Form, InputGroup, Modal } from "react-bootstrap";
import apiService from "../../../config/api"; // Import your API service
import { useMediaQuery, useTheme, Box } from "@mui/material";
import Sidebar from "../components/Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../components/AdminNavbar";
import "./AllEmployees.css";

const AllEmployees = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await apiService.get("/admin/employees");
      setEmployees(response.data);
      setNoDataFound(false);
      setSearchPerformed(false);
    } catch (error) {
      console.error("Failed to fetch employees", error);
    }
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === "") return;

    try {
      const response = await apiService.get(
        `/admin/search-employee?name=${searchTerm}`
      );
      if (response.data.users.length === 0) {
        setNoDataFound(true);
        setEmployees([]);
      } else {
        setEmployees(response.data.users);
        setNoDataFound(false);
      }
      setSearchPerformed(true);
    } catch (error) {
      console.error("Search failed", error);
      setNoDataFound(true);
    }
  };

  const handleClearSearch = async () => {
    setSearchTerm("");
    setSearchPerformed(false);
    setNoDataFound(false);
    fetchEmployees();
  };

  const handleEdit = (employeeId) => {
    navigate(`/admin/addEmployForm/${employeeId}`);
  };

  const handleDelete = async () => {
    try {
      await apiService.delete(`/admin/delete-employee/${deleteEmployeeId}`);
      setShowDeleteModal(false);
      fetchEmployees();
    } catch (error) {
      console.error("Delete failed", error);
    }
  };

  const openDeleteModal = (employeeId) => {
    setDeleteEmployeeId(employeeId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Sidebar
        open={sidebarOpen}
        toggleDrawer={toggleDrawer}
        setOpen={setSidebarOpen}
      />
      <div>
        <AdminNavbar />
        <div className="container mt-4">
          <h2>Employee List</h2>

          {/* Search Bar */}
          <InputGroup className="mb-3 position-relative">
            <Form.Control
              type="text"
              placeholder="Search employees"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown} // Search on Enter key
            />
            <Button variant="primary" onClick={handleSearch}>
              Search
            </Button>
            <button onClick={handleClearSearch} className="ms-2 btn btn-accent">
              Clear
            </button>
          </InputGroup>

          {searchPerformed && (
            <p>
              Search results for: <strong>{searchTerm}</strong>
            </p>
          )}

          {noDataFound && (
            <p className="text-danger">
              No employees found for the search term:{" "}
              <strong>{searchTerm}</strong>
            </p>
          )}

          <div className="mb-2">
            <button
              className="btn btn-accent"
              onClick={() => navigate(`/admin/addEmployForm`)}
            >
              Add Agent
            </button>
          </div>

          {/* Employee Table */}
          <div className="custom-width-allEmployee" style={{ overflowX: 'auto' }}>
            <Table striped bordered hover className="text-center">
              <thead>
                <tr className="aestyle">
                  <th>S no</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Verified</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {employees &&
                  employees.map &&
                  employees.map((employee, index) => (
                    <tr key={employee.id} className="aestyle">
                      <td>{index + 1}</td>
                      <td>
                        {employee.firstName} {employee.lastName}
                      </td>
                      <td>{employee.email}</td>
                      <td>
                        <span
                          style={{
                            backgroundColor: employee.verified
                              ? "var(--bs-primary)"
                              : "var(--bs-accent)",
                            color: employee.verified
                              ? "var(--bs-accent)"
                              : "var(--bs-primary)",
                            padding: "2px 6px",
                            borderRadius: "4px",
                            fontSize: "0.8em",
                            width: "80px",
                            display: "inline-block",
                            textAlign: "center",
                          }}
                        >
                          {employee.verified ? "Verified" : "Unverified"}
                        </span>
                      </td>
                      <td>
                        <EditIcon
                          style={{
                            color: "var(--bs-primary)",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                          onClick={() => handleEdit(employee._id)}
                        />
                        <DeleteIcon
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => openDeleteModal(employee._id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          {/* Delete Confirmation Modal */}
          <Modal show={showDeleteModal} onHide={closeDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this employee?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Box>
  );
};

export default AllEmployees;
