import React, { useState } from "react";
import "./AboutUs.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("Rent");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Navbar />

      <div className="pagecolor mt-3">
        <div className="heroes min-vh-100 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 col-md-12 mb-3 d-flex justify-content-center">
                    <img
                      src="\assets\images\buyPageImg\butterfly-house-martis-camp-united-states-2-1024x683.webp"
                      alt="Top Image"
                      className="img-fluid custom-image "
                      style={{
                        width: "600px",
                        height: "auto",
                        borderRadius: "30px",
                        marginTop: "50px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 hero-text-about ">
                <h1
                  className="m-5"
                  style={{
                    color: "var(--bs-accent)",
                    fontFamily: "Bahnschrift SemiBold",
                    fontWeight: "bold",
                  }}
                >
                  About Us
                </h1>
                <p
                  className=" contant"
                  style={{
                    color: " var(--bs-secondary)",
                    fontSize: "16px",
                  }}
                >
                  Eleganza was founded in 2013 by UK friends and property
                  experts James Perry, Luke Remington, and Simon Baker. In an
                  industry known for money-based wins and pushy comms, they
                  strived to build something different – a collaborative team
                  environment with a strong focus on client satisfaction and
                  long term relationships.
                </p>
               
              </div>
            </div>
          </div>
        </div>

        <div className="container reviews">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 mb-1">
              <div className="about-review-box">
                <h3
                  className="mb-2 mt-1 contantheading
                  "
                  style={{
                    color: "var(--bs-accent)",
                    fontFamily: "Bahnschrift SemiBold",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Where it all start
                </h3>
                <p
                  className="text-center contant"
                  style={{
                    color: " var(--bs-secondary)",
                    fontSize: "16px",
                  }}
                >
                  Eleganza was founded in 2013 by UK friends and property
                  experts James Perry, Luke Remington, and Simon Baker. In an
                  industry known for money-based wins and pushy comms, they
                  strived to build something different – a collaborative team
                  environment with a strong focus on client satisfaction and
                  long term relationships.
                </p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="row ">
                  <div className="col-12 mb-3  d-flex justify-content-center">
                    <img
                      src="\assets\images\buyPageImg\butterfly-house-martis-camp-united-states-2-1024x683.webp"
                      alt="Top Image"
                      className="img-fluid custom-image "
                      style={{
                        width: "600px",
                        height: "auto",
                        borderRadius: "30px",
                        marginTop: "50px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 hero-text-about"
                style={{ alignContent: "center" }}
              >
                <h1
                  className="m-5"
                  style={{
                    color: "var(--bs-accent)",
                    fontFamily: "Bahnschrift SemiBold",
                    fontWeight: "bold",
                  }}
                >
                  Meeting with client
                </h1>
                <p
                  className=" contant"
                  style={{
                    color: " var(--bs-secondary)",
                    fontSize: "16px",
                  }}
                >
                  Eleganza was founded in 2013 by UK friends and property
                  experts James Perry, Luke Remington, and Simon Baker. In an
                  industry known for money-based wins and pushy comms, they
                  strived to build something different – a collaborative team
                  environment with a strong focus on client satisfaction and
                  long term relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6" style={{ alignContent: "center" }}>
              <div className="col-lg-12 col-sm-12 col-md-12">
                <div className="about-review-box ">
                  <h1
                    className="m-5 hero-text-about"
                    style={{
                      color: "var(--bs-accent)",
                      fontFamily: "Bahnschrift SemiBold",
                      fontWeight: "bold",
                      marginTop: "50px",
                      alignContent: "center",
                    }}
                  >
                    Meeting with client
                  </h1>
                  <p
                    className=" contant"
                    style={{
                      color: " var(--bs-secondary)",
                      fontSize: "16px",
                    }}
                  >
                    Eleganza was founded in 2013 by UK friends and property
                    experts James Perry, Luke Remington, and Simon Baker. In an
                    industry known for money-based wins and pushy comms, they
                    strived to build something different – a collaborative team
                    environment with a strong focus on client satisfaction and
                    long term relationships.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  ">
              <div className="row">
                <div className="col-12 mb-3  d-flex justify-content-center">
                  <img
                    src="\assets\images\buyPageImg\butterfly-house-martis-camp-united-states-2-1024x683.webp"
                    alt="Top Image"
                    className="img-fluid custom-image "
                    style={{
                      width: "600px",
                      height: "auto",
                      borderRadius: "30px",
                      marginTop: "50px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;