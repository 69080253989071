import React, { useState } from 'react';
import { Container, Grid, Typography, Divider, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import CareerModal from '../careerModal/CareerModal';
import { Link } from 'react-router-dom';

const Footer = () => {

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <Box sx={{ bgcolor: 'var(--bs-primary)', color: 'white', pt: 4, pb: 3, color: 'var(--bs-accent)' }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3} sx={{ marginRight: { md: '20px' } }}>
                        <Typography variant="h6" gutterBottom>
                            Stay in the loop
                        </Typography>
                        <Typography>
                            News and insight straight to your inbox. We don't spam.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Services
                        </Typography>
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Residential Sales</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Residential Leading</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Off Plan</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Commercial Properties</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Property Management</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Resources
                        </Typography>
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Real Estate Guides</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>News & Insights</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Market Reports</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Instant Property Valuations</Link>
                        <br />
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>Podcast</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} style={{ cursor: 'pointer' }}>
                        <Typography variant="h6" gutterBottom>
                            About
                        </Typography>
                        <Typography>Our Story</Typography>
                        <Typography>Our Teams</Typography>
                        <Typography>Client Reviews</Typography>
                        <Typography
                            onClick={handleOpenModal}
                        >
                            Careers
                        </Typography>                        <Typography>Contact</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 3, bgcolor: 'white' }} />
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    <Box display="flex" flexWrap="wrap" gap={2}>
                        {/* <Link href="/termsAndConditions" color="inherit" underline="none">/ Terms and Conditions  </Link>
                        <Link href="/privacyPolicy" color="inherit" underline="none">/ Privacy Policy  </Link> */}
                        <Link to="/termsAndConditions" style={{ color: 'inherit', textDecoration: 'none' }}>
                            / Terms and Conditions
                        </Link>
                        <Link to="/privacyPolicy" style={{ color: 'inherit', textDecoration: 'none' }}>
                            / Privacy Policy
                        </Link>
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>/ Cookie Policy  </Link>
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}>/ Feedback</Link>
                        <Link style={{ color: 'inherit', textDecoration: 'none' }}> / Contact</Link>
                    </Box>
                    <Typography variant="body2" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        © 2024 Eleganza Group. All Rights Reserved.
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <IconButton href="#" color="inherit">
                            <FacebookIcon />
                        </IconButton>
                        <IconButton href="#" color="inherit">
                            <TwitterIcon />
                        </IconButton>

                        <IconButton href="#" color="inherit">
                            <InstagramIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Container>
            <CareerModal show={showModal} handleClose={handleCloseModal} />

        </Box>
    );
};

export default Footer;