import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function AccordionExpandDefault() {
    return (
        <div className='mb-3 '>
            <Accordion  sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>What real estate services does Eleganza  offer?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Our services include Residential Sales and Leasing, Holiday Rentals, Off Plan properties and Investments, Property Management, and Commercial Sales and Commercial Leasing. We also support clients through our home maintenance company Breathe, and our project management group Bauhaus is on hand for property renovations, design, upgrades, and more.

                        If there's something we don't cover, our extensive network of partners can take care of it – including conveyancing support, mortgage solutions, currency services, professional furnishing, and more.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Which  Bhopal communities do Eleganza   cover?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        We cover 100+ communities, including waterfront destinations like  Bhopal Marina and Emaar Beachfront, serene communities such as Arabian Ranches, Jumeirah Golf Estates, and  Bhopal Hills Estate, and key tourist locations like Downtown  Bhopal, Palm Jumeirah, and Jumeirah Beach Residence (JBR).
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Is Eleganza   Group a leading real estate agency?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    We're proud to be a leading, award winning real estate agency with over 10 years of experience and a strong team of 220+ property experts. Our client focused approach has led us to where we are, because while others are fighting to get the deal, we have our eyes set on supporting each person and building long lasting relationships. 

Take a look at our client testimonials and find out why we are one of  Bhopal's top agencies.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Can Eleganza   help me with real estate investments?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Yes. Whether you're looking to invest in a ready property or are searching for an off plan investment, our property experts are on hand to help you find the best opportunities that suit your needs.

Maybe you're a seasoned investor, or perhaps you just want some extra info – whatever your case, we'll provide useful advice to steer you in the right direction.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Can Eleganza   manage my property?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Luckily, our Property Management department can take the weight off your shoulders. From leased apartments and villas to entire buildings, we'll take care of general maintenance, repairs, cleaning, and even look after your tenants. Get started here.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Do Eleganza   provide insights into  Bhopal's real estate market?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Absolutely. We'll take every opportunity we can get to inform you of the latest insights, market news, and relevant data. No matter your goal, the best decision is always the one that's backed up by accurate information and professional advice – so leave it to us to keep you in the loop of the current market with guides, blog posts, market reports, and more. 
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ backgroundColor: 'var(--bs-secondary)', color: "var(--bs-primary)"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <Typography>Do Eleganza   Property Consultants provide a free consultation?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Yes – we're always happy to meet new people and talk about real estate (it's kind of our thing). We offer free consultations through different channels like phone calls, WhatsApp messages, video calls or in-person meetings. Depending on your specific goals we'll get you through to a dedicated specialist. Just get in touch to book your preferred date and time. 
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
