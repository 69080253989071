import React, { useEffect, useState } from "react";
import Sidebar from "../components/EmployeeSidebar";
import EmployeeNavbar from "../components/EmployeeNavbar";
import { Box, useMediaQuery, useTheme, Snackbar, Alert } from "@mui/material";
import ApiService from "../../../config/api";
import PropertyDetailsModal from "./PropertyDetailsModal";
import "./AssignedLeads.css";
const AssignedLeads = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const [assignedQueries, setAssignedQueries] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchAssignedQueries = async () => {
      try {
        const response = await ApiService.get("/admin/assignedQueries");
        setAssignedQueries(response?.data?.assignedQueries);
      } catch (error) {
        console.error("Error fetching assigned queries:", error);
      }
    };

    fetchAssignedQueries();
  }, []);

  const handlePropertyClick = async (propertyId) => {
    try {
      const response = await ApiService.get(`/admin/property/${propertyId}`);
      if (response.data.meta.property) {
        setSelectedProperty(response.data.meta.property);
        setModalOpen(true);
      } else {
        setSnackbarMessage("Property not found or deleted by admin");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching property details:", error);
      setSnackbarMessage("Error fetching property details");
      setSnackbarOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProperty(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Sidebar
        open={sidebarOpen}
        toggleDrawer={toggleDrawer}
        setOpen={setSidebarOpen}
      />

      <div>
      <EmployeeNavbar/>
      <div className="container mt-4">

        <h4>Assigned Queries</h4>
        <table className="table table-striped">
          <thead
            style={{
              color: "var(--bs-accent) !important",
              backgroundColor: "var(--bs-primary)!important",
              borderColor: "var(--bs-primary)!important",
            }}
          >
            <tr className="aedlistyle">
              <th
                style={{
                  color: "var(--bs-accent) !important",
                  backgroundColor: "var(--bs-primary)!important",
                  borderColor: "var(--bs-primary)!important",
                  textAlign: "center",
                }}
              >
                Name
              </th>
              <th
                style={{
                  color: "var(--bs-accent) !important",
                  backgroundColor: "var(--bs-primary)!important",
                  borderColor: "var(--bs-primary)!important",
                  textAlign: "center",
                }}
              >
                Email
              </th>
              <th
                style={{
                  color: "var(--bs-accent) !important",
                  backgroundColor: "var(--bs-primary)!important",
                  borderColor: "var(--bs-primary)!important",
                  textAlign: "center",
                }}
              >
                Phone
              </th>
              <th
                style={{
                  color: "var(--bs-accent) !important",
                  backgroundColor: "var(--bs-primary)!important",
                  borderColor: "var(--bs-primary)!important",
                  textAlign: "center",
                }}
              >
                Created At
              </th>
              <th
                style={{
                  color: "var(--bs-accent) !important",
                  backgroundColor: "var(--bs-primary)!important",
                  borderColor: "var(--bs-primary)!important",
                  textAlign: "center",
                }}
              >
                Priority
              </th>
              <th
                style={{
                  color: "var(--bs-accent) !important",
                  backgroundColor: "var(--bs-primary)!important",
                  borderColor: "var(--bs-primary)!important",
                  textAlign: "center",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {assignedQueries.map((query) => (
              <tr key={query._id}>
                <td
                  className="aedlistyle"
                  style={{
                    border: "1px, solid, var(--bs-primary)!important",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {query.queryId[0].name}
                </td>
                <td
                  className="aedlistyle"
                  style={{
                    border: "1px, solid, var(--bs-primary)!important",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {query.queryId[0].email}
                </td>
                <td
                  className="aedlistyle"
                  style={{
                    border: "1px, solid, var(--bs-primary)!important",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {query.queryId[0].phone}
                </td>
                <td
                  className="aedlistyle"
                  style={{
                    border: "1px, solid, var(--bs-primary)!important",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {new Date(query.queryId[0].createdAt).toLocaleString()}
                </td>
                <td
                  className="aedlistyle"
                  style={{
                    border: "1px, solid, var(--bs-primary)!important",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {query.priority}
                </td>
                <td
                  className="aedlistyle"
                  style={{
                    border: "1px, solid, var(--bs-primary)!important",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  <button
                    className="btn btn aedlibtn "
                    onClick={() =>
                      handlePropertyClick(query.queryId[0].propertyId)
                    }
                  >
                    Property Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      <PropertyDetailsModal
        open={modalOpen}
        onClose={handleCloseModal}
        property={selectedProperty}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignedLeads;
