import axios from 'axios';


const API_BASE_URL = 'https://eleganzaestatesolutions.com/api/';


const apiService = axios.create({
    baseURL: API_BASE_URL,
});

apiService.interceptors.request.use(
    (config) => {
        // Add token or other auth headers if needed
        const token = localStorage.getItem('accessToken'); // or use any other method to get the token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);



apiService.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle global errors
        console.error('API call error:', error.response || error.message);
        return Promise.reject(error);
    }
);

const ApiService = {
    get: (url, config) => apiService.get(url, config),        //search
    getFilter: (url, params) => apiService.get(url, { params }),   //filter

    post: (url, data) => apiService.post(url, data),
    put: (url, data) => apiService.put(url, data),
    delete: (url) => apiService.delete(url),
    getAll: (url) => apiService.get(url),
    search: (url) => apiService.search(url)
};



// ALL
export const LOGIN = API_BASE_URL + "user/login";
export const REGISTER = API_BASE_URL + "user/register";
export const VERIFICATION_STATUS_URL = API_BASE_URL + "user/register";
export const OTP = API_BASE_URL + "user/verify-otp";
export const RESEND_OTP = API_BASE_URL + "user/resend-otp";
export const FORGOT_PASS = API_BASE_URL + "user/forgot-password";
export const RESET_PASS = API_BASE_URL + "user/reset-password";
export const All_PROPERTIES = API_BASE_URL + "admin/properties";

export default ApiService;
