

// import React, { useState } from 'react';
// import ApiService from '../config/api';
// import { useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [step, setStep] = useState(1); // Step 1 for email input, Step 2 for OTP, new password, and email
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   // Validate input fields
//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

//   const validateForm = () => {
//     let formErrors = {};
//     if (!email || !validateEmail(email)) {
//       formErrors.email = 'Please enter a valid email address.';
//     }
//     if (step === 2) {
//       if (!otp) {
//         formErrors.otp = 'OTP is required.';
//       }
//       if (!newPassword) {
//         formErrors.newPassword = 'New password is required.';
//       } else if (newPassword.length < 6) {
//         formErrors.newPassword = 'Password must be at least 6 characters long.';
//       }
//     }
//     return formErrors;
//   };

//   // Handle email submission
//   const handleEmailSubmit = async (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length === 0) {
//       try {
//         const response = await ApiService.post('user/forgot-password', { email });
//         if (response.status === 200) {
//           toast.success('OTP sent to your email successfully.');
//           setStep(2); // Move to step 2
//         }
//       } catch (error) {
//         toast.error('Failed to send OTP. Please try again.');
//       }
//     } else {
//       setErrors(formErrors);
//       toast.error('Please fix the errors in the form.');
//     }
//   };

//   // Handle OTP and new password submission
//   const handleResetPasswordSubmit = async (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length === 0) {
//       try {
//         const response = await ApiService.post('user/reset-password', { email, otp, newPassword });
//         if (response.status === 200) {
//           toast.success('Password reset successful.');
//           navigate('/login'); // Navigate to login page
//         }
//       } catch (error) {
//         toast.error(error.response.data.message);
//         console.log(error.response.data.message,"aman")
//       }
//     } else {
//       setErrors(formErrors);
//       toast.error('Please fix the errors in the form.');
//     }
//   };

//   return (
//     <div className="container" style={{ color: 'var(--bs-primary)' }}>
//       <h2 className="mt-5" style={{ color: 'var(--bs-secondary)' }}>Forgot Password</h2>

//       {/* Toast Notifications */}
//       <ToastContainer />

//       {/* Step 1: Email input */}
//       {step === 1 && (
//         <form onSubmit={handleEmailSubmit} className="mt-3">
//           <div className="mb-3">
//             <label htmlFor="email" className="form-label" style={{ color: 'var(--bs-primary)' }}>Email</label>
//             <input
//               type="email"
//               className="form-control"
//               id="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//             {errors.email && <small className="text-danger">{errors.email}</small>}
//           </div>
//           <button type="submit" className="btn" style={{ backgroundColor: 'var(--bs-primary)', color: '#fff' }}>Send OTP</button>
//         </form>
//       )}

//       {/* Step 2: OTP, New Password, and Email input */}
//       {step === 2 && (
//         <form onSubmit={handleResetPasswordSubmit} className="mt-3">
//           <div className="mb-3">
//             <label htmlFor="email" className="form-label" style={{ color: 'var(--bs-primary)' }}>Email</label>
//             <input
//               type="email"
//               className="form-control"
//               id="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//             {errors.email && <small className="text-danger">{errors.email}</small>}
//           </div>
//           <div className="mb-3">
//             <label htmlFor="otp" className="form-label" style={{ color: 'var(--bs-secondary)' }}>OTP</label>
//             <input
//               type="text"
//               className="form-control"
//               id="otp"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//             />
//             {errors.otp && <small className="text-danger">{errors.otp}</small>}
//           </div>
//           <div className="mb-3">
//             <label htmlFor="newPassword" className="form-label" style={{ color: 'var(--bs-accent)' }}>New Password</label>
//             <input
//               type="password"
//               className="form-control"
//               id="newPassword"
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//               required
//             />
//             {errors.newPassword && <small className="text-danger">{errors.newPassword}</small>}
//           </div>
//           <button type="submit" className="btn" style={{ backgroundColor: 'var(--bs-primary)', color: '#fff' }}>Reset Password</button>
//         </form>
//       )}
//     </div>
//   );
// };

// export default ForgotPassword;









import React, { useState } from "react";
import ApiService from "../config/api";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState(1); // Step 1 for email input, Step 2 for OTP, new password, and email
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!email || !validateEmail(email)) {
      formErrors.email = "Please enter a valid email address.";
    }
    if (step === 2) {
      if (!otp) {
        formErrors.otp = "OTP is required.";
      }
      if (!newPassword) {
        formErrors.newPassword = "New password is required.";
      } else if (newPassword.length < 6) {
        formErrors.newPassword = "Password must be at least 6 characters long.";
      }
    }
    return formErrors;
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await ApiService.post("user/forgot-password", {
          email,
        });
        if (response.status === 200) {
          toast.success("OTP sent to your email successfully.");
          setStep(2); // Move to step 2
        }
      } catch (error) {
        toast.error("Failed to send OTP. Please try again.");
      }
    } else {
      setErrors(formErrors);
      toast.error("Please fix the errors in the form.");
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await ApiService.post("user/reset-password", {
          email,
          otp,
          newPassword,
        });
        if (response.status === 200) {
          toast.success("Password reset successful.");
          navigate("/login"); // Navigate to login page
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      setErrors(formErrors);
      toast.error("Please fix the errors in the form.");
    }
  };

  return (
    <div style={{ backgroundColor: "var(--bs-primary)" }}>
      <div className="container" style={{ color: "var(--bs-primary)" }}>
        <div style={{ backgroundColor: "var(--bs-primary)" }}>
          <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div
              className="row border rounded-5 p-3 shadow box-area"
              style={{
                maxWidth: "1000px",
                backgroundColor: "var(--bs-secondary)",
                padding: "200px",
              }}
            >
              <div
                className="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box"
                style={{
                  backgroundColor: "var(--bs-primary)",
                  padding: "3rem",
                }}
              >
                <div className="featured-image mb-3">
                  <img
                    src="../../assets/images/logo/logo coloured high quality-23 (1).png"
                    className="img-fluid"
                    style={{ width: "500px" }}
                    alt="Logo"
                    onClick={() => navigate(`/`)}
                  />
                </div>
              </div>

              <div className="col-md-6 right-box" style={{ padding: "40px" }}>
                <div className="row align-items-center">
                  <div
                    className="header-text mb-4"
                  >
                    <h2> Forgot Password</h2>
                  </div>
                  {/* Toast Notifications */}
                  <ToastContainer />
                  {step === 1 && (
                    <form onSubmit={handleEmailSubmit} className="mt-1">
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {errors.email && (
                          <small className="text-danger">{errors.email}</small>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn"
                        style={{
                          backgroundColor: "var(--bs-primary)",
                          color: "var(--bs-accent)",
                        }}
                      >
                        Send OTP
                      </button>
                    </form>
                  )}

                  {step === 2 && (
                    <form onSubmit={handleResetPasswordSubmit} className="mt-3">
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          className="form-label"
                          style={{ color: "var(--bs-primary)" }}

                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          placeholder="Enter Email"

                        />
                        {errors.email && (
                          <small className="text-danger">{errors.email}</small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="otp"
                          className="form-label"
                          style={{ color: "var(--bs-primary)" }}
                        >
                          OTP
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          required
                          placeholder="Enter OTP"

                        />
                        {errors.otp && (
                          <small className="text-danger">{errors.otp}</small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="newPassword"
                          className="form-label"
                          style={{ color: "var(--bs-primary)" }}
                        >
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                          placeholder="Enter New Password"

                        />
                        {errors.newPassword && (
                          <small className="text-danger">
                            {errors.newPassword}
                          </small>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="btn"
                        style={{
                          backgroundColor: "var(--bs-primary)",
                          color: "var(--bs-accent)",
                        }}
                      >
                        Reset Password
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
