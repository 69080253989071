// import React, { useState, useRef } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const EmployeeOtpVerify = () => {
//     const [otp, setOtp] = useState(new Array(6).fill(''));
//     const [resendText, setResendText] = useState('Resend');
//     const [modalVisible, setModalVisible] = useState(false);
//     const [modalMessage, setModalMessage] = useState('');
//     const inputs = useRef([]);
//     const location = useLocation();
//     const navigate = useNavigate();
//     const { email } = location.state || {}; // Get the email from location.state

//     const handleChange = (text, index) => {
//         let newOtp = [...otp];
//         newOtp[index] = text;

//         if (text.length === 1 && index < 5) {
//             inputs.current[index + 1]?.focus();
//         }
//         if (text.length === 0 && index > 0) {
//             inputs.current[index - 1]?.focus();
//         }

//         setOtp(newOtp);
//     };

//     const handleSubmit = async () => {
//         const otpCode = otp.join('');
//         try {
//             const response = await axios.post('http://localhost:8715/api/user/verify-otp', { email, otp: otpCode });
//             if (response.status === 200) {
//                 setModalMessage('OTP verified successfully');
//                 setModalVisible(true);
//                 setTimeout(() => {
//                     setModalVisible(false);
//                     navigate('/admin/allEmployees'); // Redirect to sign-in page
//                 }, 2000);
//             } else {
//                 setModalMessage('Please check your OTP and try again.');
//                 setModalVisible(true);
//                 setTimeout(() => setModalVisible(false), 2000);
//             }
//         } catch (error) {
//             setModalMessage('An error occurred while verifying OTP. Please try again.');
//             setModalVisible(true);
//             setTimeout(() => setModalVisible(false), 2000);
//         }
//     };

//     const handleResend = async () => {
//         setResendText('Resending...');

//         try {
//             const response = await axios.post('http://localhost:8714/api/user/resend-otp', { email });
//             console.log(response,"fardeen khan");
//             if (response.status === 200) {
//                 setOtp(new Array(6).fill(''))

//                 setModalMessage('OTP resent successfully');
//             } else {
//                 setModalMessage('Failed to resend OTP. Please try again.');
//             }
//         } catch (error) {
//             setModalMessage('An error occurred while resending OTP. Please try again.');
//         }
//         setModalVisible(true);
//         setResendText('Resend');
//         setTimeout(() => setModalVisible(false), 2000);
//     };

//     return (
//         <div className="container mt-5">
//             <div className="text-center mb-4">
//                 <h1 className="text-primary">OTP Verification</h1>
//                 {email && <p className="text-muted">We have sent a One Time Passcode to your email: <strong>{email}</strong></p>}
//             </div>

//             <div className="d-flex justify-content-center mb-3">
//                 <div className="otp-inputs d-flex gap-2">
//                     {otp.map((value, index) => (
//                         <input
//                             key={index}
//                             ref={(ref) => (inputs.current[index] = ref)}
//                             value={value}
//                             onChange={(e) => handleChange(e.target.value, index)}
//                             maxLength={1}
//                             className="form-control text-center"
//                             style={{ width: '50px', fontSize: '1.5rem' }}
//                         />
//                     ))}
//                 </div>
//             </div>

//             <div className="text-center mb-3">
//                 <p>Didn't receive the OTP? <span onClick={handleResend} className="text-primary" style={{ cursor: 'pointer' }}>{resendText}</span></p>
//             </div>

//             <div className="text-center">
//                 <button onClick={handleSubmit} className="btn btn-success">Verify OTP</button>
//             </div>

//             {modalVisible && (
//                 <div className="modal fade show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
//                     <div className="modal-dialog">
//                         <div className="modal-content">
//                             <div className="modal-body text-center">
//                                 <p>{modalMessage}</p>
//                                 <button onClick={() => setModalVisible(false)} className="btn btn-secondary">Close</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default EmployeeOtpVerify;






// import React, { useState, useRef } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import ApiService from "../config/api"; // Adjust the import path for ApiService
// import "./EmployeeOtpVerify.css";

// const EmployeeOtpVerify = () => {
//   const [otp, setOtp] = useState(new Array(6).fill(""));
//   const [resendText, setResendText] = useState("Resend");
//   const [modalVisible, setModalVisible] = useState(false);
//   const [modalMessage, setModalMessage] = useState("");
//   const inputs = useRef([]);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { email } = location.state || {}; // Get the email from location.state

//   const handleChange = (text, index) => {
//     let newOtp = [...otp];
//     newOtp[index] = text;

//     if (text.length === 1 && index < 5) {
//       inputs.current[index + 1]?.focus();
//     }
//     if (text.length === 0 && index > 0) {
//       inputs.current[index - 1]?.focus();
//     }

//     setOtp(newOtp);
//   };

//   const handleSubmit = async () => {
//     console.log("123")
//     // console.log(ApiService.post(`/user/verify-otp`))
//     const otpCode = otp.join("");
//     try {
//       const response = await ApiService.post(`/user/verify-otp`,
//         { email, otp: otpCode }
//       );
//       if (response.status === 200) {
//         setModalMessage("OTP verified successfully");
//         setModalVisible(true);
//         setTimeout(() => {
//           setModalVisible(false);
//           navigate("/admin/allEmployees");
//         }, 2000);
//       } else {
//         setModalMessage("Please check your OTP and try again.");
//         setModalVisible(true);
//         setTimeout(() => setModalVisible(false), 2000);
//       }
//     } catch (error) {
//       setModalMessage(
//         "An error occurred while verifying OTP. Please try again."
//       );
//       setModalVisible(true);
//       setTimeout(() => setModalVisible(false), 2000);
//     }
//   };

//   const handleResend = async () => {
//     setResendText("Resending...");

//     try {
//       const response = await ApiService.post(
//         "/user/resend-otp",
//         { email }
//       );
//       if (response.status === 200) {
//         setOtp(new Array(6).fill(""));

//         setModalMessage("OTP resent successfully");
//       } else {
//         setModalMessage("Failed to resend OTP. Please try again.");
//       }
//     } catch (error) {
//       setModalMessage(
//         "An error occurred while resending OTP. Please try again."
//       );
//     }
//     setModalVisible(true);
//     setResendText("Resend");
//     setTimeout(() => setModalVisible(false), 2000);
//   };

//   return (
//     <div className="container mt-5">
//       <div className="text-center mb-4">
//         <h1 className="Heading-otp" style={{ marginTop: "30vh" }}>
//           OTP Verification
//         </h1>
//         {email && (
//           <p className="text-muted">
//             We have sent a One Time Passcode to your email:{" "}
//             <strong>{email}</strong>
//           </p>
//         )}
//       </div>

//       <div className="d-flex justify-content-center mb-3">
//         <div className="otp-inputs d-flex gap-2">
//           {otp.map((value, index) => (
//             <input
//               key={index}
//               ref={(ref) => (inputs.current[index] = ref)}
//               value={value}
//               onChange={(e) => handleChange(e.target.value, index)}
//               maxLength={1}
//               className="form-control text-center"
//               style={{ width: "50px", fontSize: "1.5rem" }}
//             />
//           ))}
//         </div>
//       </div>

//       <div className="text-center mb-3">
//         <p>
//           Didn't receive the OTP?{" "}
//           <span
//             onClick={handleResend}
//             className="text-primary"
//             style={{ cursor: "pointer" }}
//           >
//             {resendText}
//           </span>
//         </p>
//       </div>

//       <div className="text-center">
//         <button onClick={handleSubmit} className="btn custom-btn">
//           Verify OTP
//         </button>
//       </div>

//       {modalVisible && (
//         <div
//           className="modal fade show d-block"
//           style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-body text-center">
//                 <p>{modalMessage}</p>
//                 <button
//                   onClick={() => setModalVisible(false)}
//                   className="btn custom-btn"
//                 >
//                   Close
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default EmployeeOtpVerify;




import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../config/api"; // Adjust the import path for ApiService
import "./EmployeeOtpVerify.css";

const EmployeeOtpVerify = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [resendText, setResendText] = useState("Resend");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const inputs = useRef([]);
  const navigate = useNavigate();
  
  const user = JSON.parse(localStorage.getItem("user"));
  const email = user?.email;


  console.log(email,'amaanemail')
  const handleChange = (text, index) => {
    let newOtp = [...otp];
    newOtp[index] = text;

    if (text.length === 1 && index < 5) {
      inputs.current[index + 1]?.focus();
    }
    if (text.length === 0 && index > 0) {
      inputs.current[index - 1]?.focus();
    }

    setOtp(newOtp);
  };

  const handleSubmit = async () => {
    const otpCode = otp.join("");
    try {
      const response = await ApiService.post(`/user/verify-otp`, {
        email, 
        otp: otpCode
      });
      if (response.status === 200) {
        setModalMessage("OTP verified successfully");
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          navigate("/employee/employeePropertyList");
        }, 2000);
      } else {
        setModalMessage("Please check your OTP and try again.");
        setModalVisible(true);
        setTimeout(() => setModalVisible(false), 2000);
      }
    } catch (error) {
      setModalMessage(
        "An error occurred while verifying OTP. Please try again."
      );
      setModalVisible(true);
      setTimeout(() => setModalVisible(false), 2000);
    }
  };

  const handleResend = async () => {
    setResendText("Resending...");

    try {
      const response = await ApiService.post("/user/resend-otp", { email });
      if (response.status === 200) {
        setOtp(new Array(6).fill(""));
        setModalMessage("OTP resent successfully");
      } else {
        setModalMessage("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      setModalMessage(
        "An error occurred while resending OTP. Please try again."
      );
    }
    setModalVisible(true);
    setResendText("Resend");
    setTimeout(() => setModalVisible(false), 2000);
  };

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h1 className="Heading-otp" style={{ marginTop: "30vh" }}>
          OTP Verification
        </h1>
        {email && (
          <p className="text-muted">
            We have sent a One Time Passcode to your email:{" "}
            <strong>{email}</strong>
          </p>
        )}
      </div>

      <div className="d-flex justify-content-center mb-3">
        <div className="otp-inputs d-flex gap-2">
          {otp.map((value, index) => (
            <input
              key={index}
              ref={(ref) => (inputs.current[index] = ref)}
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              maxLength={1}
              className="form-control text-center"
              style={{ width: "50px", fontSize: "1.5rem" }}
            />
          ))}
        </div>
      </div>

      <div className="text-center mb-3">
        <p>
          Didn't receive the OTP?{" "}
          <span
            onClick={handleResend}
            className="text-primary"
            style={{ cursor: "pointer" }}
          >
            {resendText}
          </span>
        </p>
      </div>

      <div className="text-center">
        <button onClick={handleSubmit} className="btn custom-btn">
          Verify OTP
        </button>
      </div>

      {modalVisible && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center">
                <p>{modalMessage}</p>
                <button
                  onClick={() => setModalVisible(false)}
                  className="btn custom-btn"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeOtpVerify;
