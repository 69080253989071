// import React from 'react';
// import { Box, Button, Modal, Typography } from '@mui/material';

// const PropertyDetailsModal = ({ open, onClose, property }) => {
//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box
//         sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 500,
//           bgcolor: 'background.paper',
//           borderRadius: '8px',
//           boxShadow: 24,
//           p: 4,
//         }}
//       >
//         {property ? (
//           <>
//             <Box
//               component="img"
//               src={property.images[0]} // Replace with the correct image URL
//               alt={property.title}
//               sx={{
//                 width: '100%',
//                 height: 'auto',
//                 borderRadius: '8px',
//                 marginBottom: 2,
//               }}
//             />
//             <Typography variant="h6" gutterBottom>
//               {property.title}
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               Price: ${property.price}
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               Bathrooms: {property.bathrooms}
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               Bedrooms: {property.bedrooms}
//             </Typography>
//             <Typography variant="body1" gutterBottom>
//               Area: {property.area} sq ft
//             </Typography>
//           </>
//         ) : (
//           <Typography>Loading...</Typography>
//         )}
//         <Button variant="contained" onClick={onClose} sx={{ mt: 2 }}>
//           Close
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default PropertyDetailsModal;



import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BathroomIcon from "@mui/icons-material/Bathtub";
import BedroomIcon from "@mui/icons-material/Bed";
import AreaIcon from "@mui/icons-material/SquareFoot";

const PropertyDetailsModal = ({ open, onClose, property }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          p: 4,
          backgroundColor: "var(--bs-secondary)",
          color: "var(--bs-primary)",
          margin: "auto",
          width: "90vw",
          height: "auto",
          maxWidth: "600px",
          borderRadius: "8px",
          boxShadow: 24,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",   maxHeight: "95vh",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 3, right: 3 }}
        >
          <CloseIcon style={{ color: "var(--bs-primary)" }} />
        </IconButton>
        {property ? (
          <>
            {/* Bootstrap Carousel */}
            <div
              id="propertyCarousel"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                {property.images.map((_, index) => (
                  <button
                    type="button"
                    key={index}
                    data-bs-target="#propertyCarousel"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner">
                {property.images.map((image, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={image}
                      className="d-block w-100"
                      alt={`${property.title} - ${index + 1}`}
                      style={{
                        height: "50vh",
                        objectFit: "cover",
                        width: "100%",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#propertyCarousel"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#propertyCarousel"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>

            {/* Property Details */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {property.title}
              </Typography>
              <Typography variant="h6" gutterBottom>
                ₹ {property.price}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "var(--bs-primary)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BathroomIcon sx={{ marginRight: 1 }} />
                Bathrooms: {property.numberOfBathrooms}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "var(--bs-primary)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BedroomIcon sx={{ marginRight: 1 }} />
                Bedrooms: {property.numberOfBedrooms}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "var(--bs-primary)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AreaIcon sx={{ marginRight: 1 }} />
                Area: {property.area} sq ft
              </Typography>
            </div>
            <Typography variant="body1" gutterBottom>
              {property.description}
            </Typography>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default PropertyDetailsModal;
