import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import ApiService from "../../../config/api"; // Adjust the import path according to your project structure
import { useNavigate } from "react-router-dom";
import Navbar from "../../webpage/components/navbar/Navbar";
const UserRegister = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPwd: "",
    roleName: "user",
    phoneNumber: "",
    age: "",
    gender: "",
  });

  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiService.post("/user/register", formData);
      console.log(response);
      if (response.status === 200) {
        setShowOtpModal(true);
      }
    } catch (error) {
      setMessage("Registration failed");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await ApiService.post("/user/verify-otp", { otp });
      if (response.success) {
        setMessage("OTP verified successfully");
        setShowOtpModal(false);
        navigate("/login");
      }
    } catch (error) {
      setMessage("OTP verification failed");
    }
  };

  const handleResendOtp = async () => {
    try {
      await ApiService.post("/user/resend-otp", { email: formData.email });
      setMessage("OTP resent successfully");
    } catch (error) {
      setMessage("Failed to resend OTP");
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "var(--bs-primary)" }}>
        {/* <Navbar /> */}
        {/*  */}

        <div className="container d-flex justify-content-center align-items-center min-vh-100  py-2">
          <div
            className="row border rounded-5 p-3 bg-white shadow box-area"
            style={{
              width: "1200px",
              padding: "200px",
              backgroundColor: "var(--bs-secondary)!important",
            }}
          >
            <div
              className="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box"
              style={{
                backgroundColor: " #122620",
                padding: "3rem",
                // backgroundColor: "var(--bs-primary)",
              }}
            >
              <div className="featured-image mb-3">
                <img
                  src="../../assets/images/logo/logo coloured high quality-23 (1).png"
                  className="img-fluid"
                  style={{ width: "500px" }}
                  alt="Logo"
                />
              </div>
            </div>

            <div className="col-md-6 right-box" style={{ padding: "40px" }}>
              <div className="row align-items-center">
                <div
                  className="header-text mb-4"
                  style={{ color: "var(--bs-primary)" }}
                >
                  <h2> Register</h2>
                  <p>We are happy to have you here</p>
                </div>
                <Form onSubmit={handleRegister}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label style={{ marginTop: "10px" }}>
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="phoneNumber">
                        <Form.Label style={{ marginTop: "10px" }}>
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="password">
                        <Form.Label style={{ marginTop: "10px" }}>
                          Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="confirmPwd">
                        <Form.Label style={{ marginTop: "10px" }}>
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPwd"
                          value={formData.confirmPwd}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="dob">
                        <Form.Label style={{ marginTop: "10px" }}>
                          Age
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="dob"
                          value={formData.dob}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="gender">
                        <Form.Label style={{ marginTop: "10px" }}>
                          Gender
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="gender"
                          value={formData.gender}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="other">Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button variant="primary" type="submit" className="mt-3">
                    Register
                  </Button>
                </Form>
                <p
                  className="mt-3"
                  style={{ textAlign: "center", color: "var(--bs-primary)" }}
                >
                  Already have an account?
                  <a href="/login" style={{ color: "var(--bs-primary)" }}>
                    Login
                  </a>
                </p>
              </div>
              {/* OTP Verification Modal */}
              <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>OTP Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="otp">
                    <Form.Label>Enter OTP</Form.Label>
                    <Form.Control
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </Button>
                  <Button
                    variant="secondary"
                    className="mt-3"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </Button>
                </Modal.Body>
              </Modal>

              {/* Message Display */}
              {message && (
                <div className="alert alert-info mt-3">{message}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegister;
