import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/webpage/contact/Contact';
import BuyPage from './pages/webpage/BuyPage/BuyPage';
import HomePage from './pages/webpage/homePage/HomePage';
import AdminDashboard from './pages/admin/adminDashboard/AdminDashboard';
import AddPropertyForm from './pages/admin/addPropertyForm/AddPropertyForm';
import AddEmployForm from './pages/admin/addEmployForm/AddEmployForm';
import PropertyDescription from './pages/webpage/propertyDescription/PropertyDescription';
import Login from './login/Login';
import SuperAdmin from './pages/superAdmin/SuperAdmin';
import ProtectedRoute from './auth/ProtectedRoute';
import { AuthProvider } from './auth/AuthContext';
import EmployeeOtpVerify from './otpVerifyEmployee/EmployeeOtpVerify';
import AddPropertyEmployee from './pages/employee/addPropertyEmployee/AddPropertyEmployee';
import AdminPropertyListing from './pages/admin/adminPropertyListing/AdminPropertyListing';
import AssignLeads from './pages/admin/assignLeads/AssignLeads';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AboutUs from './pages/webpage/Aboutus/AboutUs';
import EditPropertyForm from './pages/admin/editPropertyForm/EditPropertyForm';
import ScrollToTop from "./ScrollToTop";
import AllEmployees from './pages/admin/allEmployees/AllEmployees';
import EditEmployeesForm from './pages/admin/editEmployForm/EditEmployeesForm';
import AssignedLeads from './pages/employee/assignedLeads/AssignedLeads';
import UserRegister from './pages/user/userRegister/UserRegister';
import UserProfile from './pages/user/userProfile/UserProfile';
import UserWishlist from './pages/user/wishlist/Wishlist';
import AdminNavbar from './pages/admin/components/AdminNavbar';
import EmployeePropertyList from './pages/employee/employeePropertyList/EmployeePropertyList';
import ForgotPassword from './forgot-password/ForgotPassword';
import TermsAndConditions from './pages/webpage/termsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/webpage/PrivacyPolicy/PrivacyPolicy';
import EditPropertyEmployee from './pages/employee/editPropertyEmployee/EditPropertyEmployee';
import AllJoinUsQueries from './pages/admin/allJoinUsQueries/AllJoinUsQueries';

const theme = createTheme({
  typography: {
    fontFamily: 'Bahnschrift SemiBold, sans-serif', // Set your desired font family here
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--bs-accent)', // Your desired focus color for input border
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--bs-primary)', // Default label color
          '&.Mui-focused': {
            color: 'var(--bs-accent)', // Label color when focused
          },
        },
      },
    },
  },
});



const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>

        <Router>
          <div className="">
            <ScrollToTop />

            <Routes>
              {/* Public */}
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/buyPage" element={<BuyPage />} />
              <Route path="propertyDescription/:id" element={<PropertyDescription />} />
              <Route path="aboutUs" element={<AboutUs />} />
              <Route path="/userRegister" element={<UserRegister />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/termsAndConditions" element={<TermsAndConditions />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/employeeOtpVerify" element={<EmployeeOtpVerify />} />

              {/* SuperAdmin */}
              <Route
                path="/superAdmin"
                element={
                  <ProtectedRoute allowedRoles={['superAdmin']}>
                    <SuperAdmin />
                  </ProtectedRoute>
                }
              />

              {/* Admin */}
              <Route
                path="/admin/*"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <>
                      {/* Add a common layout component for admin here if needed */}
                      {/* <AdminNavbar/> */}
                      <Routes>
                        <Route path="adminDashboard" element={<AdminDashboard />} />
                        <Route path="addPropertyForm" element={<AddPropertyForm />} />
                        <Route path="editPropertyForm/:id" element={<EditPropertyForm />} />
                        <Route path="addEmployForm" element={<AddEmployForm />} />
                        <Route path="addEmployForm/:id" element={<AddEmployForm />} />
                        <Route path="editEmployForm/:id" element={<EditEmployeesForm />} />
                        <Route path="adminPropertyListing" element={<AdminPropertyListing />} />
                        <Route path="assignLeads" element={<AssignLeads />} />
                        <Route path="allEmployees" element={<AllEmployees />} />
                        <Route path="allJoinUsQueries" element={<AllJoinUsQueries />} />

                        {/* Add other admin routes here */}
                      </Routes>
                    </>
                  </ProtectedRoute>
                }
              />



              <Route
                path="/user/*"
                element={
                  <ProtectedRoute allowedRoles={['user']}>
                    <>
                      <Routes>
                      <Route path="userProfile" element={<UserProfile />} />
                      <Route path="userWishlist" element={<UserWishlist />} />

                      </Routes>
                    </>
                  </ProtectedRoute>
                }
              />




              <Route
                path="/employee/*"
                element={
                  <ProtectedRoute allowedRoles={['employee']}>
                    <>
                      {/* Add a common layout component for admin here if needed */}
                      <Routes>
                        <Route path="employeePropertyForm" element={<AddPropertyEmployee />} />
                        <Route path="editPropertyEmployee/:id" element={<EditPropertyEmployee />} />
                        <Route path="assignedLeads" element={<AssignedLeads />} />
                        <Route path="employeePropertyList" element={<EmployeePropertyList />} />

                        {/* Add other admin routes here */}
                      </Routes>
                    </>
                  </ProtectedRoute>
                }
              />



              {/* Fallback for unauthorized access */}
              <Route path="*" element={<Login />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>

    </AuthProvider>
  );
};

export default App;
