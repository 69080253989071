
import '../homePage/HomePage.css'
import { Link } from 'react-router-dom';
import React, { Component, useEffect, useRef, useState } from "react";
import StarIcon from '@mui/icons-material/Star';
import PoolIcon from '@mui/icons-material/Pool';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import GamesIcon from '@mui/icons-material/Games';
import ParkIcon from '@mui/icons-material/Park';
import DiamondIcon from '@mui/icons-material/Diamond';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import Faq from '../components/faq/Faq';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../components/navbar/Navbar'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RoomIcon from '@mui/icons-material/Room';

import Footer from "../components/footer/Footer";
import ApiService from '../../../config/api';
import { useNavigate } from "react-router-dom";

import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';

import PropertyInquiryModal from "../BuyPage/PropertyInquiryModal ";
import TimeAgo from '../components/timeAgo/TimeAgo';


function VerticalMode({ image1, image2, image3, image4, image5, delay }) {

  const sliderRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.slickPlay();
      }
    }, delay);

    // return () => clearTimeout(timer);
  }, [delay]);

  const settings = {
    arrows: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: true,

    dots: false,
    infinite: true,

    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
    },
    afterChange: function (currentSlide) {
    }
  };
  return (
    <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
          <span className="wrapper"><img src={image1} className="mb-4 " alt="" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image2} className="mb-4 " alt="" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image3} className="mb-4 " alt="" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image4} alt="" className="mb-4" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image5} alt="" className="mb-4 " /></span>
        </div>

      </Slider>
    </div>
  );
}




const HomePage = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const [properties, setProperties] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const navigate = useNavigate(); // React Router's useNavigate hook

  // Function to handle search query submission
  const handleSearch = async () => {
    try {
      const response = await ApiService.get(`/admin/search`, {
        params: {
          searchValue: searchQuery, // Match the parameter name expected by the backend
        },
      });
      console.log("Search Results:", response.data.data);
      navigate("/buyPage", { state: { searchResults: response.data.data } });

    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const getDataFromServer = async () => {
    try {
      const successResponse = await ApiService.getAll(`/user/public-listing`);
      console.log(successResponse, "amaann");
      const { meta: { properties } } = successResponse.data;

      const uniqueProperties = properties.filter((property, index, self) => {
        return index === self.findIndex((p) => p.title === property.title);
      });
  
      // Set the unique properties to state
      setProperties(uniqueProperties);

      // setProperties(properties)
    } catch (error) {
      console.error(error)
    }
  }
  // useEffect(){

  //   getDataFromServer()
  // };
  useEffect(() => {
    getDataFromServer();
  }, []); // Empty dependency array means this will run once on component mount

  const openInquiryModal = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  
  return (

    <div className='background-main'>
      <Navbar className="" />
      {/* ------hero------------------ */}

      <div className='hero  d-flex align-items-center text-center'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 hero-text ">
              <h1>Real Estate Reimagined. Find your perfect place</h1>
              <p className='mb-5'>
                Discover your dream home with us. Expert guidance, personalized service, and a vast selection of properties await. Let's find your perfect place today.
              </p>
              <div className="inline search-bar-container">
                <input
                  type="text"
                  className="form-control search-form"
                  placeholder="City, building or country"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleKeyPress}  // Handle Enter key press
                />
                {searchQuery && (
                  <button className="clear-btn absolute" onClick={handleClearSearch}>
                    &times;
                  </button>
                )}
                <button className="btn search-btn btn-hoverrr" type="button" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>





      {/*  New Card*/}
      <div className="container  feature-section">
        <div className="col-lg-8">
          <h2 className="text-color">Featured Properties</h2>
        </div>
        <div className="row my-4">

          {properties.slice(0, 6).map((property, index) => (
            <div key={index} className="col-md-6 col-lg-4 col-sm-12">
              <div className="property-card">
                <div className="property-image" onClick={() => navigate(`/propertyDescription/${property._id}`)}>
                  <img
                    src={property.images[0]}
                    className="img-fluid"
                    alt="Property"
                  />
                </div>

                <div className="property-details">
                  <div className='d-flex justify-content-between my-2'>
                    <h5 className="mb-0 homePage-title-heading" >{property.title || "Property"}</h5>
                    <p className="property-price mb-0">
                      <CurrencyRupeeIcon fontSize="16" />
                      {property.price.toLocaleString() || "Get a quote"}
                    </p>
                  </div>

                  <div className='d-flex justify-content-between my-2'>

                    <p className="text-muted m-0 ">{property.location || " India"}</p>
                    <p className="text-muted m-0 "><TimeAgo createdAt={property.createdAt} /></p>
                  </div>


                  <div className="dot-container mb-2 text-card-new mt-2">
                    <span className='d-flex align-items-center'>
                      <BedIcon style={{ fontSize: 18, marginRight: 4 }} />
                      {property.numberOfBedrooms}
                    </span>
                    <div className="dot"></div>
                    <span className='d-flex align-items-center'>
                      <BathtubIcon style={{ fontSize: 18, marginRight: 4, marginBottom: 4 }} />
                      {property.numberOfBathrooms }
                    </span>
                    <div className="dot"></div>
                    <span className='d-flex align-items-center'>
                      <SquareFootIcon style={{ fontSize: 18, marginRight: 4 }} />
                      {property.area }
                    </span>
                  </div>
                </div>

                <div className="property-buttons">
                  <button className="btn" onClick={() => navigate(`/propertyDescription/${property._id}`)}>
                    View Details
                  </button>
                  <button className="btn" onClick={() => openInquiryModal(property._id)}>
                    Get a Quote
                  </button>
                </div>
              </div>
            </div>
          ))}

         





        </div>


<div className=' d-flex justify-content-end  '>
<button onClick={() => navigate(`/buyPage`)} className='btn-outline px-3 py-2 rounded-2 bg-transparent 'style={{color:'var(--bs-accent)',border:'2px solid var(--bs-accent)'}}>See more</button>
{/* <button className='btn-outline px-3 py-2 rounded-2  'style={{color:'var(--bs-primary)',border:'2px solid var(--bs-accent)',backgroundColor:'var(--bs-secondary)'}}>See more</button> */}

</div>


      </div>




      {/* -------------------------explore---------------------------------- */}
      {/* <hr style={{ border: 0, height: '1px', backgroundColor: 'var(--bs-accent)', margin: '20px 0', opacity: 0.7 }} /> */}


      <div className="container reviews ">

        {/* <div className="block d-flex flex-wrap  justify-content-around ">
          <div className="col-lg-5 col-md-12 col-sm-12 item item-one mt-4">
            <h2 className='mb-3'>Bhopal Real Estate</h2>
            <p className="mb-4">Covering 100+ communities, we can help you find one that ticks your boxes.</p>
            <div className="explore-btn mt-1">
              <button className=" btn-hoverrr " target="_self" href="/guides/area-guides-dubai/">explore</button>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 item item-two mt-4">
            <h2 className="mb-3">Find Your Consultant</h2>
            <p className="mb-4">Search our team of 220+ property experts to get professional guidance.</p>
            <form>
              <div className="input-group  mx-2">


                <input type="text" className="input-control border-start-0 border-input " aria-label="Name" aria-describedby="basic-addon1" placeholder="Name or department" />


                <button className=" btn-accent btn-hoverrr " type="button" id="button-addon2" style={{ borderRadius: "50%", width: "48px", padding: "16px 0 0", height: "45px", border: "1px solid var(--bs-accent)" }}><SearchIcon style={{ marginTop: "-16px", color: "#fff" }} className='' /></button>
              </div>
            </form>
          </div>
        </div> */}

        <div className="row mt-5">
          <div className="col-lg-12 col-sm-12 col-md-12">

            <div className="explore-box text-color">
              <h3>
                Discover your dream home with our extensive real estate listings. From cozy apartments to luxurious villas, find properties that suit your lifestyle and budget. Start your journey to a new home today.
              </h3>


            </div>

          </div>
        </div>

      </div>


      {/* ----------------Featured Property----------------- */}
      {/* <div className="container  feature-section">

        <div className="row my-4">
          <div className="col-lg-8">
            <h2 className="text-color">Featured Property</h2>
          </div>


        </div>
        <div className="row hide features-property">

          {properties.map((property, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 feature-card mb-5">
              <div className='features rounded-3'>
                <img src={property?.images[0]} alt={`Property ${index}`} />
              </div>

              <p className=' mt-2 mb-0 d-flex align-items-center justify-content-between  text-color'>
                <span style={{ fontSize: 19 }}>{property.title}</span>
                <span className="d-flex align-items-center" style={{ fontSize: 19 }}>
                  <CurrencyRupeeIcon fontSize="16" />
                  {property.price.toLocaleString()}
                </span>
              </p>


              <div className='d-flex align-items-center mb-2 text-color'>
                <RoomIcon style={{ fontSize: 16 }} />
                <p className='property-address mb-0 fw-light'>{property.city}</p>
              </div>


              <div className="dot-container mb-2 text-card ">
                <span className='d-flex align-items-center fw-light'>
                  <BedIcon style={{ fontSize: 16, marginRight: 4 }} />
                  {property.numberOfBedrooms} Beds
                </span>
                <div className="dot"></div>

                <span className='d-flex align-items-center fw-light'>
                  <BathtubIcon style={{ fontSize: 16, marginRight: 4 }} />
                  {property.numberOfBathrooms} Full Baths
                </span>
                <div className="dot"></div>

                <span className='d-flex align-items-center fw-light'>
                  <SquareFootIcon style={{ fontSize: 16, marginRight: 4 }} />
                  {property.area} Sq.Ft.
                </span>
              </div>

            </div>
          ))}





        </div>


      </div> */}






      {/*  */}
      {/* ------------------------search-property---------------------- */}
      {/* <div className="container search-features mt-4">
        <div className="row">
          <div className="col-lg-10">
            <h2 className="text-color">Search properties by feature</h2>
          </div>


        </div>



        <div className="row mt-3">
          <div className="col-12">
            <div className="search-property " role="group" aria-label="Property Features">

              <button type="button" className="btn btn-outline-accent feature-btn "><PoolIcon className='me-2' />Private Pool</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><FormatPaintIcon className='me-2' />Upgraded</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><GamesIcon className='me-2' />Large Plot</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><ParkIcon className='me-2' />to Park</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><DiamondIcon className='me-2' />Brand New</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><SingleBedIcon className='me-2' />Furnished</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><TransferWithinAStationIcon className='me-2' />Vacant on Transfer</button>
              <button type="button" className="btn btn-outline-accent feature-btn"><NaturePeopleIcon className='me-2' />Water Views</button>
            </div>
          </div>
        </div>

      </div> */}
      {/* ----------------------------instance-valuation-------------------------------*/}
      <div className="container  padding-box mt-4 mb-5 " >
        <div className=" row  rounded-4 box-color">

          <div className="col-lg-6 col-md-12 col-sm-12 valuation-module d-flex flex-column justify-content-center">
            <div className="">
              <h3 className='valuation-text'>Find Your Dream Home Today</h3>

            </div>
            <p className='valuation-text'>Finding a home that fits your lifestyle has never been easier. Browse through our curated selection of properties, and let us guide you to the home that aligns with your dreams and needs. Begin your search today. </p>

          </div>
          <div className="col-lg-6   carousal-wrapper">

            <span className='d-flex'>
              <VerticalMode image1="/assets/images/homePageImg/vc1.webp"
                image2="/assets/images/homePageImg/vc1.2.webp"
                image3="/assets/images/homePageImg/vc1.3.webp"
                image4="/assets/images/homePageImg/vc1.4.webp"
                image5="/assets/images/homePageImg/vc1.5.webp"
                delay={300}
              />

              <VerticalMode image1="/assets/images/homePageImg/vc2.webp"
                image2="/assets/images/homePageImg/vc2.2.webp"
                image3="/assets/images/homePageImg/vc2.3.webp"
                image4="/assets/images/homePageImg/vc2.4.webp"
                image5="/assets/images/homePageImg/vc2.5.webp"
                delay={300}
              />

              <VerticalMode image1="/assets/images/homePageImg/v3.webp"
                image2="/assets/images/homePageImg/vc3.2.webp"
                image3="/assets/images/homePageImg/vc3.3.webp"
                image4="/assets/images/homePageImg/vc3.4.webp"
                image5="/assets/images/homePageImg/vc3.5.webp"
                delay={2000}
              />

            </span>
          </div>

        </div>
      </div>





      {/* -----------------accordian---------------------- */}
      <div className='accordion mt-4'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="mb-3 mt-3 "><h2>Why choose Eleganza</h2></div>

              <Faq></Faq>
            </div>
          </div>
        </div>
      </div>

      <PropertyInquiryModal open={modalOpen} handleClose={closeModal} propertyId={selectedPropertyId} />

      <Footer />

    </div >


  )
}

export default HomePage
