import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { role } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = () => {
  
      localStorage.clear();

      navigate('/login');
    };

    if (!role) {
      logout();
    } else if (!allowedRoles.includes(role)) {
      logout();
    }
  }, [role, allowedRoles, navigate]);

  return allowedRoles.includes(role) ? children : null;
};

export default ProtectedRoute;
