import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import ApiService from '../../../config/api'; // Adjust the path accordingly
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const PropertyInquiryModal = ({ open, handleClose, propertyId }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    let tempErrors = {};
    if (!name) tempErrors.name = 'Name is required';
    if (!email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Email is not valid';
    }
    if (!phone) tempErrors.phone = 'Phone number is required';
    else if (!/^\d{10}$/.test(phone)) {
      tempErrors.phone = 'Phone number must be 10 digits';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    const data = {
      propertyId,
      name,
      email,
      phone,
    };

    try {
      await ApiService.post('/user/query', data);  // API Call to submit the form data
      handleClose(); // Close modal
      navigate(`/propertyDescription/${propertyId}`);
    } catch (error) {
      console.error('Error submitting query:', error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyle }}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Inquire About Property
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: 'var(--bs-primary)',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={Boolean(errors.name)}
          helperText={errors.name}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={Boolean(errors.email)}
          helperText={errors.email}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Phone"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          error={Boolean(errors.phone)}
          helperText={errors.phone}
          sx={{ marginBottom: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ backgroundColor: 'var(--bs-primary)', '&:hover': { backgroundColor: 'var(--bs-accent)' } }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bs-secondary)',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  color:'var(--bs-primary)'
};

export default PropertyInquiryModal;
