import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundColor: "var(--bs-primary)",
          color: "var(--bs-secondary)",
        }}
      >
        <br />

        <div className="container privacy-policy ">
          <h1
            style={{
              color: "var(--bs-accent)",

              marginTop: "100px",
            }}
          >
            Privacy Policy
          </h1>
          <p>
            We, at Info Edge India Limited and our affiliated companies
            worldwide, are committed to respecting your online privacy and
            recognize your need for appropriate protection and management of any
            personally identifiable information you share with us.
          </p>
          <p>
            This Privacy Policy (“Policy”) governs our website available at
            99acres.com and our mobile application (collectively, the
            “Platform”). The Policy describes how Info Edge India Limited
            (hereinafter referred to as the “Company”) collects, uses, discloses
            and transfers personal data of users while browsing the Platform or
            availing specific services therein (the “Services”).
          </p>
          <p>
            This Policy describes how we process personal data of all users of
            our Platform or Services, including buyers, renters, owners,
            dealers, brokers, and website visitors.
          </p>
          <p className="privacy-policy">
            “Personal Data” means any data about an individual who is
            identifiable by or in relation to such data.
          </p>
          <p className="privacy-policy">
            By providing your consent to this Policy, either on the Platform or
            through other means, or accessing the Platform and Services, you
            consent to the Company’s processing of your Personal Data in
            accordance with this Policy. Where required, for processing your
            Personal Data for distinct purposes, we seek your consent separately
            on the Platform or through other means.
          </p>
          <p>This Privacy Policy is divided into the following sections:</p>
          <ul>
            <li>Personal Data we collect</li>
            <li>How we use your Personal Data</li>
            <li>Who we share your Personal Data with</li>
            <li>Data storage and retention</li>
            <li>Your rights</li>
            <li>Data protection practices</li>
            <li>Third-party websites, apps, and services</li>
            <li>Children</li>
            <li>Changes to the privacy policy</li>
            <li>How to contact us – Grievance office</li>
          </ul>
          <h3 style={{ color: "var(--bs-accent)" }}>
            1. Personal Data we collect
          </h3>
          <p>
            We collect the following types of Personal Data about you when you
            access our Platform or Services:
          </p>
          <section>
            <h3 style={{ color: "var(--bs-accent)" }}>
              A. Information you give us:
            </h3>
            <p>
              We collect information you provide to us directly when you use our
              Platforms (like when you sign-up/register an account, or post
              property listings). We may also collect this information over
              calls, emails, messages, or other communications established with
              you to create, update, or maintain your details on the Platform.
              Further, we may also collect this information when you provide it
              by filling out relevant forms to express your interest in availing
              our Services. This information includes:
            </p>
            <ul>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Personal Details:
                </strong>
                This includes your name, contact information (like addresses,
                e-mail addresses, phone number), and login information (like
                username).
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Property Details:
                </strong>{" "}
                This includes the details of the property such as nature of the
                property (like commercial, residential, etc.), location details,
                property profile (like area, dimensions, road connectivity),
                photographs of your property (if any), pricing details, and
                details of the amenities.
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Identification Documents:
                </strong>{" "}
                This includes any information that helps us identify and verify
                you (such as Aadhaar, voter id, driver’s license, PAN, passport,
                etc.) or your property (such as utility bills, sales deed,
                property registration documents, property tax documents, etc.).
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Payment Information:
                </strong>{" "}
                We use third-party payment service providers to process your
                payments. You will be redirected to a third-party interface that
                will collect the required information from you to process the
                payment transaction. The payment transaction will also be
                governed by the policies of such third-party service provider,
                including their privacy policy. We may receive certain
                transaction details from third-party payment service providers.
                However, we do not store and do not have access to your
                financial information such as your bank account numbers,
                credit/debit card numbers, or UPI handle.
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Communications Details:
                </strong>{" "}
                This includes records of your calls, emails, messages, or your
                communications in other forms related to any feedback, comments,
                queries, issue reports, customer support, or other
                communications that you send to us, among other things.
              </li>
            </ul>
          </section>
          <section>
            <h3 style={{ color: "var(--bs-accent)" }}>
              B. Information we collect when you use our Platforms:
            </h3>
            <p>
              When you use our Platforms, we collect certain types of
              information about how you use or access our Platforms. This
              includes:
            </p>
            <ul>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Usage data:
                </strong>{" "}
                Data collected and processed about you when you access the
                Platform, such as your search queries, intent capture, your
                account settings, times at which you access the Platforms, time
                spent on certain pages, inferences of your interests and
                preferences based on your usage.
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Technical data:
                </strong>{" "}
                Technical data may include data about the devices, software and
                technology you use such as hardware model, operating system, IP
                address, preferred languages, unique device identifiers,
                advertising identifiers, serial numbers, device motion data,
                network data and web browser details. General or approximate
                location data: General location data, such as your city, region,
                or country. This may be understood from technical data such as
                your IP address.
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Communication data:
                </strong>{" "}
                When you communicate with the Platform or use the Platform to
                communicate with other members (such as other users,
                advertisers, dealers and builders etc.), the Company collects
                and stores information about your communication and any other
                information you provide.{" "}
                <strong style={{ color: "var(--bs-accent)" }}>
                  Cookies data:
                </strong>{" "}
                We use cookies or other similar technologies (like web beacons,
                log files, etc.) to recognize your device, remember you and
                support the continuity of your experience. Cookies are files
                which are downloaded to your device when you access our website
                or web apps.
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  Purchase or transactional data:
                </strong>{" "}
                Transactional details, such as details about your purchase
                orders and the payments to and from your accounts with us.
                However, we do not store and do not have access to your
                financial information such as your bank account numbers,
                credit/debit card numbers, or UPI handle.
              </li>
              <li>
                <strong style={{ color: "var(--bs-accent)" }}>
                  App-specific permissions:
                </strong>{" "}
                This includes permissions for Notifications, Camera, Location,
                Phone (Dialer), Photos and videos on mobile devices. Most
                app-specific permissions require manual approval by you. If you
                have given the Platform access to non-essential permissions, but
                wish to turn them off, you can do so through your phone’s
                settings. The app may not function in case you revoke certain
                essential app permissions. Since app permissions can change from
                time to time, we may provide additional information about
                specific app permissions within the app, or on the
                Platform-specific app store, such as on the App Store or Play
                Store. Insight data: We may use your data to derive insights
                into your usage of our Platform.
              </li>
            </ul>
          </section>
          <section>
            <h3 style={{ color: "var(--bs-accent)" }}>
              C. Information we receive from other sources:
            </h3>
            <ul>
              <li>
                We may receive information about you from third parties, such as
                advertising and marketing partners. We may collect your
                information through property campaigns and publicly available
                sources.
              </li>
              <li>
                We may also receive information from third parties when you use
                a third-party account, like SSO/MFA/2FA providers, to log in or
                upload information to the Platform. This information may include
                name, email, phone number, and other details related to your
                third-party accounts.
              </li>
            </ul>
          </section>
          <h3 style={{ color: "var(--bs-accent)" }}>
            2. How we use your Personal Data
          </h3>
          <p>We use your Personal Data for the following purposes:</p>
          <ul>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                To provide you with our Services:
              </strong>{" "}
              This includes responding to your queries, processing your property
              listings, assisting you with your requests and inquiries, and
              other related services.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                To improve and customize our Platform:
              </strong>{" "}
              We analyze how you use our Platform to enhance user experience,
              provide features and services tailored to your interests, and
              perform analytics to understand trends and user behavior.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                For communication purposes:
              </strong>{" "}
              To send you updates, newsletters, and marketing communications,
              and to keep you informed about our Services, offers, or changes to
              the Platform.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                For administrative and legal purposes:
              </strong>{" "}
              To comply with legal obligations, enforce our terms, prevent
              fraud, and respond to legal requests or disputes.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                For business and research purposes:
              </strong>{" "}
              To conduct research, analysis, and business planning to improve
              our Platform, Services, and user experiences.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                To support security:
              </strong>{" "}
              To protect against fraudulent or unauthorized activities, monitor
              and analyze usage patterns, and maintain the security of our
              Platform.
            </li>
          </ul>
          <h3 style={{ color: "var(--bs-accent)" }}>
            3. Who we share your Personal Data with
          </h3>
          <p>We may share your Personal Data with:</p>
          <ul>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                Service Providers:
              </strong>{" "}
              Third-party vendors who assist us in providing services, such as
              payment processors, IT service providers, and marketing agencies.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                Business Partners:
              </strong>{" "}
              Third parties with whom we may collaborate to offer services or
              conduct joint marketing activities.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                Legal Authorities:
              </strong>{" "}
              Government authorities, law enforcement agencies, or legal
              entities in response to legal obligations, requests, or
              enforcement actions.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                Business Transfers:
              </strong>{" "}
              In the event of a merger, acquisition, or reorganization of our
              business, we may transfer your Personal Data as part of such
              transactions.
            </li>
          </ul>
          <h3 style={{ color: "var(--bs-accent)" }}>
            4. Data storage and retention
          </h3>
          <p>
            We retain your Personal Data only for as long as necessary to
            fulfill the purposes outlined in this Policy, or as required by law.
            We have measures in place to protect your Personal Data from
            unauthorized access or disclosure.
          </p>
          <h3 style={{ color: "var(--bs-accent)" }}>5. Your rights</h3>
          <p>
            You have certain rights regarding your Personal Data, including:
          </p>
          <ul>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>Access:</strong> You
              can request a copy of the Personal Data we hold about you.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>Correction:</strong>{" "}
              You can request to correct or update any inaccurate or incomplete
              Personal Data.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>Deletion:</strong>{" "}
              You can request to delete your Personal Data, subject to certain
              legal exceptions.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                Restriction:
              </strong>{" "}
              You can request to restrict the processing of your Personal Data
              in certain situations.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>Objection:</strong>{" "}
              You can object to the processing of your Personal Data for certain
              purposes.
            </li>
            <li>
              <strong style={{ color: "var(--bs-accent)" }}>
                Data Portability:
              </strong>{" "}
              You can request to receive your Personal Data in a structured,
              commonly used, and machine-readable format.
            </li>
          </ul>
          <h3 style={{ color: "var(--bs-accent)" }}>
            6. Data protection practices
          </h3>
          <p>
            We implement various technical and organizational measures to
            protect your Personal Data from unauthorized access, disclosure, or
            loss. This includes encryption, access controls, and secure servers.
          </p>
          <h3 style={{ color: "var(--bs-accent)" }}>
            7. Third-party websites, apps, and services
          </h3>
          <p>
            Our Platform may contain links to third-party websites or services.
            We are not responsible for the privacy practices or content of these
            third parties. We recommend reviewing their privacy policies before
            providing any Personal Data.
          </p>
          <h3 style={{ color: "var(--bs-accent)" }}>8. Children</h3>
          <p>
            Our Platform and Services are not intended for children under the
            age of 18. We do not knowingly collect Personal Data from children.
            If we become aware that we have collected Personal Data from a
            child, we will take steps to delete such data.
          </p>
          <h3 style={{ color: "var(--bs-accent)" }}>
            9. Changes to the privacy policy
          </h3>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date. We
            encourage you to review this Policy periodically.
          </p>
          <h3 style={{ color: "var(--bs-accent)" }}>
            10. How to contact us – Grievance office
          </h3>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our handling of your Personal Data, please contact our Grievance
            Office at:
          </p>
          <hr />
          <br />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
