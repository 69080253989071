import React, { createContext, useContext, useState, useEffect } from 'react';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [role, setRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedRole = localStorage.getItem('role');
    if (storedRole) {
      setRole(storedRole);
    }
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ role, setRole }}>
      {/* {!isLoading ? children : <div>Loading...</div>} */}
      {!isLoading ? children : ""}
    </AuthContext.Provider>
  );
};
