import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import { TextField, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Navbar from '../components/Navbar';
import { BarChart } from '@mui/x-charts/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FoundationIcon from '@mui/icons-material/Foundation';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div>
            <Box
                display="grid"
                gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
                sx={{
                    transition: 'grid-template-columns 0.3s ease-in-out',
                    overflow: 'hidden',
                }}
            >
                <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
                {/*  */}
                <div>
                    <Navbar />

                    <div>
                        <div className="container backg" >
                            <div className="row">
                                <div className="col-md-4 col-lg-3">
                                    <div className="box ">
                                        <div className='d-flex justify-content-around'><h5>Earning</h5>
                                            <MonetizationOnIcon />
                                        </div>


                                        <h6>600</h6></div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="box">
                                        <div className='d-flex justify-content-around'><h5>Sales</h5>
                                            <AttachMoneyIcon />
                                        </div>
                                        <h6>400</h6></div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="box">
                                        <div className='d-flex justify-content-around'>
                                            <h5>Property</h5>
                                            <FoundationIcon />
                                        </div>
                                        <h6>500</h6></div>
                                </div>
                                <div className="col-md-4 col-lg-3 ">
                                    <div className="box">
                                        <div className='d-flex justify-content-around'> <h5>Stock</h5>
                                            <ShowChartIcon />
                                        </div>
                                        <h6>400</h6></div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-around">
                                <div className="chart-bg col-lg-5 mt-4 chart-padding">
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                                        series={[
                                            { data: [4, 3, 5], color: '#09371a' }, // Custom color for the first series
                                            { data: [1, 6, 3], color: '#D6AD60' }, // Custom color for the second series
                                            { data: [2, 5, 6], color: '#09371a' }, // Custom color for the third series
                                        ]}
                                        // width={500}
                                        height={300}
                                    />
                                </div>
                                <div className="chart-bg col-lg-5 mt-4 chart-padding">
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                                        series={[
                                            { data: [4, 3, 5], color: '#09371a' }, // Custom color for the first series
                                            { data: [1, 6, 3], color: '#D6AD60' }, // Custom color for the second series
                                            { data: [2, 5, 6], color: '#09371a' }, // Custom color for the third series
                                        ]}
                                        // width={500}
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                {/*  */}

            </Box>
        </div>
    )
}

export default AdminDashboard