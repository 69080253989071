import React, { useState } from 'react';

const EmployeeNavbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // Fetching role and user details from local storage
    const role = localStorage.getItem('employee') || 'employee'; // Default role is 'Admin'
    const user = JSON.parse(localStorage.getItem('user')) || {
        firstName: '',
        lastName: '',
        phoneNumber: '',
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <nav className="navbar" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <h4 className='mb-0'>Employee</h4>
                <div className="d-flex align-items-center">
                    <div className="text-end me-2">
                        <div>{user.firstName} {user.lastName}</div>
                        <div style={{ fontSize: '0.8rem' }}>{role}</div>
                    </div>
                    <div className="dropdown">
                        <div
                            onClick={toggleDropdown}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div className="avatar-circle"
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#d6ab5e',
                                    color: '#122620',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '10px',
                                }}>
                                {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                            </div>
                        </div>
                        {dropdownOpen && (
                            <ul
                                className="dropdown-menu dropdown-menu-end show"
                                style={{ position: 'absolute', right: 0, marginTop: '20px', backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}
                            >
                                <li className="dropdown-item" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
                                    <strong>Name:</strong> {user.firstName} {user.lastName}
                                </li>
                                <li className="dropdown-item" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
                                    <strong>Phone:</strong> {user.phoneNumber}
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleLogout}
                                        style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}
                                    >
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default EmployeeNavbar  ;
