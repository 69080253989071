import React, { useState } from "react";
import "./AddPropertyForm.css";
import Sidebar from "../components/Sidebar";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ApiService from "../../../config/api"; // Adjust the path as per your file structure
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AdminNavbar from "../components/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPropertyForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const predefinedAmenities = [
    "Gym",
    "Swimming Pool",
    "Parking",
    "Garden",
    "Clubhouse",
    "Playground",
    "24/7 Security",
    "Power Backup",
    "Elevator",
    "Wi-Fi Connectivity",
  ];

  const predefinedNearbyPlaces = [
    "Shopping Mall",
    "Hospital",
    "School",
    "Public Transport",
    "Restaurant",
    "Bank",
    "Supermarket",
    "Park",
    "Gym",
    "Cinema",
  ];

  const [property, setProperty] = useState({
    title: "",
    description: "",
    price: "",
    discountPrice: "",
    floor: "",
    area: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    latitude: "",
    longitude: "",
    numberOfBedrooms: "",
    numberOfBathrooms: "",
    constructionYear: "",
    agentName: "",
    agentPhone: "",
    agentEmail: "",
    amenities: [],
    nearbyPlaces: [],
    images: [],
    images360: "",
    threeDModels: "",
    availableQuantity: "",
    totalQuantity: "",
  });

  const [errorMessages, setErrorMessages] = useState({});
  const [nearbyPlace, setNearbyPlace] = useState("");
  const [amenity, setAmenity] = useState("");
  const [customAmenity, setCustomAmenity] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const navigate = useNavigate();
  const validatePropertyForm = (property) => {
    const errors = {};
    let isValid = true;

    if (!property.title.trim()) {
      errors.title = "Title is required";
      isValid = false;
    }

    if (!property.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    if (property.price === "" || property.price < 0) {
      errors.price = "Price must be a non-negative number";
      isValid = false;
    }

    if (property.discountPrice === "" || property.discountPrice < 0) {
      errors.discountPrice = "Discount Price must be a non-negative number";
      isValid = false;
    }

    if (property.floor === "" || property.floor < 0) {
      errors.floor = "Floor must be a non-negative number";
      isValid = false;
    }

    if (property.area === "" || property.area < 0) {
      errors.area = "Area must be a non-negative number";
      isValid = false;
    }

    if (!property.city.trim()) {
      errors.city = "City is required";
      isValid = false;
    }

    if (!property.state.trim()) {
      errors.state = "State is required";
      isValid = false;
    }

    if (!property.country.trim()) {
      errors.country = "Country is required";
      isValid = false;
    }

    if (!property.pincode.trim()) {
      errors.pincode = "Pincode is required";
      isValid = false;
    }

    if (property.latitude === "" || property.latitude < 0) {
      errors.latitude = "Latitude must be a non-negative number";
      isValid = false;
    }

    if (property.longitude === "" || property.longitude < 0) {
      errors.longitude = "Longitude must be a non-negative number";
      isValid = false;
    }

    if (property.numberOfBedrooms === "" || property.numberOfBedrooms < 0) {
      errors.numberOfBedrooms =
        "Number of Bedrooms must be a non-negative number";
      isValid = false;
    }

    if (property.numberOfBathrooms === "" || property.numberOfBathrooms < 0) {
      errors.numberOfBathrooms =
        "Number of Bathrooms must be a non-negative number";
      isValid = false;
    }

    if (property.constructionYear === "" || property.constructionYear < 0) {
      errors.constructionYear =
        "Number of Bathrooms must be a non-negative number";
      isValid = false;
    }

    if (!property.constructionYear.trim()) {
      errors.constructionYear = "Construction Year is required";
      isValid = false;
    }

    // if (!property.agentName.trim()) {
    //   errors.agentName = "Agent Name is required";
    //   isValid = false;
    // }

    // const phoneRegex = /^[0-9]{10}$/;
    // if (!property.agentPhone.trim()) {
    //   errors.agentPhone = "Agent Phone is required";
    //   isValid = false;
    // } else if (!phoneRegex.test(property.agentPhone)) {
    //   errors.agentPhone = "Agent Phone must be a valid 10-digit number";
    //   isValid = false;
    // }

    // const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    // if (!property.agentEmail.trim()) {
    //   errors.agentEmail = "Agent Email is required";
    //   isValid = false;
    // } else if (!emailRegex.test(property.agentEmail)) {
    //   errors.agentEmail = "Agent Email must be a valid email address";
    //   isValid = false;
    // }

    return { isValid, errors };
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const newValue =
  //     name.includes("number") ||
  //     name === "price" ||
  //     name === "discountPrice" ||
  //     name === "floor" ||
  //     name === "area" ||
  //     name === "latitude" ||
  //     name === "longitude"
  //       ? Math.max(0, value)
  //       : value;

  //   setProperty({
  //     ...property,
  //     [name]: newValue,
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue =
      name.includes("number") ||
      name === "price" ||
      name === "discountPrice" ||
      name === "floor" ||
      name === "area" ||
      name === "latitude" ||
      name === "longitude" ||
      name === "agentPhone"
        ? Math.max(0, value)
        : value;

    setProperty({
      ...property,
      [name]: newValue,
    });

    // Remove the error message for the current field when valid
    if (errorMessages[name] && value.trim()) {
      setErrorMessages((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Remove the specific error
        return updatedErrors;
      });
    }
  };

  //   const handleFileChange = (e) => {
  //     const files = Array.from(e.target.files);
  //     const newImages = [...property.images, ...files];
  //     console.log(newImages, "images console");
  //     const newPreviews = newImages.map((file) => URL.createObjectURL(file));
  //     setProperty({ ...property, images: newImages });
  //     setImagePreviews(newPreviews);
  //   };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = [...property.images, ...files];
    console.log(newImages, "images console");
    const newPreviews = newImages.map((file) => URL.createObjectURL(file));
    setProperty({ ...property, images: newImages });
    setImagePreviews(newPreviews);
  };

  const handleRemoveImage = (index) => {
    const newImages = [...property.images];
    const newPreviews = [...imagePreviews];

    // Remove the selected image and its preview
    newImages.splice(index, 1);
    newPreviews.splice(index, 1);

    // Update state with the remaining images and previews
    setProperty({ ...property, images: newImages });
    setImagePreviews(newPreviews);
  };

  const handle360ImageChange = (e) => {
    const file = e.target.files[0];
    setProperty({
      ...property,
      images360: URL.createObjectURL(file),
    });
  };

  const handle3DModelChange = (e) => {
    const file = e.target.files[0];
    setProperty({
      ...property,
      threeDModels: URL.createObjectURL(file),
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const { isValid, errors } = validatePropertyForm(property);
      if (!isValid) {
        setErrorMessages(errors);
        return;
      }

      const formdata = new FormData();
      for (const key in property) {
        if (Array.isArray(property[key])) {
          property[key].forEach((value, index) => {
            if (key === "images") {
              formdata.append(`${key}`, value);
            } else {
              formdata.append(`${key}[${index}]`, value);
            }
          });
        } else {
          formdata.append(key, property[key]);
        }
      }

      const response = await ApiService.post(
        "admin/create-properties",
        formdata
      );
      console.log("Property created successfully:", response.data);
      toast.success(response.data.message);
      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      console.error("Error creating property:", error);
      toast.error("Error creating property:", error); 

      // Handle error, e.g., show an error message
    }
  };

  const handleRemovePlace = (index) => {
    setProperty((prevProperty) => {
      const updatedPlaces = [...prevProperty.nearbyPlaces];
      updatedPlaces.splice(index, 1);
      return { ...prevProperty, nearbyPlaces: updatedPlaces };
    });
  };

  const handleRemoveAmenity = (index) => {
    setProperty((prevProperty) => ({
      ...prevProperty,
      amenities: prevProperty.amenities.filter((_, i) => i !== index),
    }));
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Sidebar
        open={sidebarOpen}
        toggleDrawer={toggleDrawer}
        setOpen={setSidebarOpen}
      />
      <div>
        <AdminNavbar />
        <div className="property-cont ">
          <div className="card rounded card-add-property g-2">
            <div
              className="px-3 py-3"
              style={{ borderBottom: "1px solid var(--bs-secondary)" }}
            >
              <h5 className="mb-0 p-2 ">Add Property</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.title ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="title"
                      placeholder="Enter Title"
                      name="title"
                      value={property.title}
                      onChange={handleChange}
                    />
                    {errorMessages.title && (
                      <p className="error-text">{errorMessages.title}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className={`form-control ${
                        errorMessages.description ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Description"
                      id="description"
                      name="description"
                      rows="1"
                      value={property.description}
                      onChange={handleChange}
                    ></textarea>
                    {errorMessages.description && (
                      <p className="error-text">{errorMessages.description}</p>
                    )}
                  </div>

                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="totalQuantity" className="form-label">
                      Total Quantity
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.latitude ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="totalQuantity"
                      placeholder="Enter total Quantity"
                      name="totalQuantity"
                      value={property.totalQuantity}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="availableQuantity" className="form-label">
                      Available Quantity
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.latitude ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="availableQuantity"
                      placeholder="Enter available Quantity"
                      name="availableQuantity"
                      value={property.availableQuantity}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="price" className="form-label">
                      Price
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.price ? "is-invalid" : ""
                      }`}
                      type="number"
                      placeholder="Enter Price"
                      id="price"
                      name="price"
                      value={property.price}
                      onChange={handleChange}
                    />
                    {errorMessages.price && (
                      <p className="error-text">{errorMessages.price}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="discountPrice" className="form-label">
                      Discount Price
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.discountPrice ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="discountPrice"
                      placeholder="Enter Discount"
                      name="discountPrice"
                      value={property.discountPrice}
                      onChange={handleChange}
                    />
                    {errorMessages.discountPrice && (
                      <p className="error-text">
                        {errorMessages.discountPrice}
                      </p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="floor" className="form-label">
                      Floor
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.floor ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Floor"
                      type="number"
                      id="floor"
                      name="floor"
                      value={property.floor}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="area" className="form-label">
                      Area
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.area ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="area"
                      name="area"
                      placeholder="Enter Area"
                      value={property.area}
                      onChange={handleChange}
                    />
                    {errorMessages.area && (
                      <p className="error-text">{errorMessages.area}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.city ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="city"
                      placeholder="Enter City"
                      name="city"
                      value={property.city}
                      onChange={handleChange}
                    />
                    {errorMessages.city && (
                      <p className="error-text">{errorMessages.city}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.state ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="state"
                      placeholder="Enter State"
                      name="state"
                      value={property.state}
                      onChange={handleChange}
                    />
                    {errorMessages.state && (
                      <p className="error-text">{errorMessages.state}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.country ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="country"
                      name="country"
                      placeholder="Enter Country"
                      value={property.country}
                      onChange={handleChange}
                    />
                    {errorMessages.country && (
                      <p className="error-text">{errorMessages.country}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="pincode" className="form-label">
                      Pincode
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.pincode ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Pincode"
                      type="text"
                      id="pincode"
                      name="pincode"
                      value={property.pincode}
                      onChange={handleChange}
                    />
                    {errorMessages.pincode && (
                      <p className="error-text">{errorMessages.pincode}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="latitude" className="form-label">
                      Latitude
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.latitude ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="latitude"
                      placeholder="Enter Latitude"
                      name="latitude"
                      value={property.latitude}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="longitude" className="form-label">
                      Longitude
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.longitude ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="longitude"
                      placeholder="Enter Longitude"
                      name="longitude"
                      value={property.longitude}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="numberOfBedrooms" className="form-label">
                      Number of Bedrooms
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.numberOfBedrooms ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="numberOfBedrooms"
                      placeholder="Enter Number"
                      name="numberOfBedrooms"
                      value={property.numberOfBedrooms}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="numberOfBathrooms" className="form-label">
                      Number of Bathrooms
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.numberOfBathrooms ? "is-invalid" : ""
                      }`}
                      type="number"
                      id="numberOfBathrooms"
                      placeholder="Enter Number"
                      name="numberOfBathrooms"
                      value={property.numberOfBathrooms}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="constructionYear" className="form-label">
                      Construction Year
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.constructionYear ? "is-invalid" : ""
                      }`}
                      type="number"
                      placeholder="Enter Construction Year"
                      id="constructionYear"
                      name="constructionYear"
                      value={property.constructionYear}
                      onChange={handleChange}
                      min="0"
                    />
                    {errorMessages.constructionYear && (
                      <p className="error-text">
                        {errorMessages.constructionYear}
                      </p>
                    )}
                  </div>

                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="agentName" className="form-label">
                      Agent Name
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.agentName ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="agentName"
                      placeholder="Enter Name"
                      name="agentName"
                      value={property.agentName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="agentPhone" className="form-label">
                      Agent Phone
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.agentPhone ? "is-invalid" : ""
                      }`}
                      type="number"
                      placeholder="Enter Phone Number"
                      id="agentPhone"
                      name="agentPhone"
                      value={property.agentPhone}
                      onChange={handleChange}
                    />
                    {errorMessages.agentPhone && (
                      <p className="error-text">{errorMessages.agentPhone}</p>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label htmlFor="agentEmail" className="form-label">
                      Agent Email
                    </label>
                    <input
                      className={`customInput form-control ${
                        errorMessages.agentEmail ? "is-invalid" : ""
                      }`}
                      type="email"
                      id="agentEmail"
                      placeholder="Enter Email"
                      name="agentEmail"
                      value={property.agentEmail}
                      onChange={handleChange}
                    />
                    {errorMessages.agentEmail && (
                      <p className="error-text">{errorMessages.agentEmail}</p>
                    )}
                  </div>

                  {/* <div className="col-md-4 col-sm-6 px-4 mb-3 list-color">
                    <label htmlFor="amenity" className="form-label">
                      Add Amenity
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        value={amenity}
                        onChange={(e) => setAmenity(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Amenity
                        </option>
                        {predefinedAmenities.map((amenity, index) => (
                          <option key={index} value={amenity}>
                            {amenity}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn-add btn-hoverrr btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          if (amenity && !property.amenities.includes(amenity)) {
                            setProperty((prevProperty) => ({
                              ...prevProperty,
                              amenities: [...prevProperty.amenities, amenity],
                            }));
                            setAmenity("");
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <ul>
                      {property.amenities.map((amenity, index) => (
                        <li key={index}>
                          {amenity}
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveAmenity(index)}
                            aria-label="remove"
                            style={{ marginLeft: "10px" }}
                          >
                            <CloseIcon
                              style={{
                                color: "var(--bs-accent)",
                                fontSize: "17px",
                              }}
                            />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <div className="col-md-4 col-sm-6 px-4 mb-3 list-color">
                    <label htmlFor="amenity" className="form-label">
                      Add Amenity
                    </label>

                    {/* Input field for both typing and dropdown selection */}
                    <div className="input-group mb-3">
                      <input
                        list="amenitiesList"
                        className="form-control"
                        placeholder="Type or select amenities"
                        value={amenity}
                        onChange={(e) => setAmenity(e.target.value)}
                      />

                      {/* Dropdown for predefined amenities */}
                      <datalist id="amenitiesList">
                        {predefinedAmenities.map((amenityOption, index) => (
                          <option key={index} value={amenityOption} />
                        ))}
                      </datalist>

                      {/* Button to add selected or custom amenity */}
                      <button
                        className="btn btn-add btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          const amenityList = amenity
                            .split(",")
                            .map((item) => item.trim()); // Allow multiple entries separated by commas
                          const newAmenities = amenityList.filter(
                            (item) => item && !property.amenities.includes(item)
                          );

                          if (newAmenities.length) {
                            setProperty((prevProperty) => ({
                              ...prevProperty,
                              amenities: [
                                ...prevProperty.amenities,
                                ...newAmenities,
                              ],
                            }));
                            setAmenity(""); // Clear input field after adding
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>

                    {/* Display added amenities */}
                    <ul>
                      {property.amenities.map((amenityItem, index) => (
                        <li key={index}>
                          {amenityItem}
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveAmenity(index)}
                            aria-label="remove"
                            style={{ marginLeft: "10px" }}
                          >
                            <CloseIcon
                              style={{
                                color: "var(--bs-accent)",
                                fontSize: "17px",
                              }}
                            />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* <div className="col-md-4 col-sm-6 px-4 mb-3 list-color">
                    <label htmlFor="nearbyPlace" className="form-label">
                      Add Nearby Place
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Add Nearby Place"
                        className="form-control"
                        value={nearbyPlace}
                        onChange={(e) => setNearbyPlace(e.target.value)}
                      />
                      <button
                        className="btn btn-add btn-hoverrr btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          setProperty((prevProperty) => ({
                            ...prevProperty,
                            nearbyPlaces: [
                              ...prevProperty.nearbyPlaces,
                              nearbyPlace,
                            ],
                          }));
                          setNearbyPlace("");
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <ul>
                      {property.nearbyPlaces.map((place, index) => (
                        <li key={index}>
                          {place}
                          <IconButton
                            size="small"
                            onClick={() => handleRemovePlace(index)}
                            aria-label="remove"
                            style={{ marginLeft: "10px" }}
                          >
                            <CloseIcon
                              style={{
                                color: "var(--bs-accent)",
                                fontSize: "17px",
                              }}
                            />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </div> */}

                  {/* <div className="col-md-4 col-sm-6 px-4 mb-3 list-color">
                    <label htmlFor="nearbyPlace" className="form-label">
                      Add Nearby Place
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        value={nearbyPlace}
                        onChange={(e) => setNearbyPlace(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Nearby Place
                        </option>
                        {predefinedNearbyPlaces.map((place, index) => (
                          <option key={index} value={place}>
                            {place}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn btn-add btn-hoverrr btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          if (nearbyPlace && !property.nearbyPlaces.includes(nearbyPlace)) {
                            setProperty((prevProperty) => ({
                              ...prevProperty,
                              nearbyPlaces: [...prevProperty.nearbyPlaces, nearbyPlace],
                            }));
                            setNearbyPlace("");
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <ul>
                      {property.nearbyPlaces.map((place, index) => (
                        <li key={index}>
                          {place}
                          <IconButton
                            size="small"
                            onClick={() => handleRemovePlace(index)}
                            aria-label="remove"
                            style={{ marginLeft: "10px" }}
                          >
                            <CloseIcon
                              style={{
                                color: "var(--bs-accent)",
                                fontSize: "17px",
                              }}
                            />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <div className="col-md-4 col-sm-6 px-4 mb-3 list-color">
                    <label htmlFor="nearbyPlace" className="form-label">
                      Add Nearby Place
                    </label>

                    {/* Input field for both typing and dropdown selection */}
                    <div className="input-group mb-3">
                      <input
                        list="placesList"
                        className="form-control"
                        placeholder="Type or select nearby places"
                        value={nearbyPlace}
                        onChange={(e) => setNearbyPlace(e.target.value)}
                      />

                      {/* Dropdown for predefined nearby places */}
                      <datalist id="placesList">
                        {predefinedNearbyPlaces.map((placeOption, index) => (
                          <option key={index} value={placeOption} />
                        ))}
                      </datalist>

                      {/* Button to add selected or custom nearby places */}
                      <button
                        className="btn btn-add btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          const placesList = nearbyPlace
                            .split(",")
                            .map((item) => item.trim()); // Allow multiple entries separated by commas
                          const newPlaces = placesList.filter(
                            (item) =>
                              item && !property.nearbyPlaces.includes(item)
                          );

                          if (newPlaces.length) {
                            setProperty((prevProperty) => ({
                              ...prevProperty,
                              nearbyPlaces: [
                                ...prevProperty.nearbyPlaces,
                                ...newPlaces,
                              ],
                            }));
                            setNearbyPlace(""); // Clear input field after adding
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>

                    {/* Display added nearby places */}
                    <ul>
                      {property.nearbyPlaces.map((placeItem, index) => (
                        <li key={index}>
                          {placeItem}
                          <IconButton
                            size="small"
                            onClick={() => handleRemovePlace(index)}
                            aria-label="remove"
                            style={{ marginLeft: "10px" }}
                          >
                            <CloseIcon
                              style={{
                                color: "var(--bs-accent)",
                                fontSize: "17px",
                              }}
                            />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="col-md-4 col-sm-6 px-4 mb-3">
                    <label className="form-label">Upload Images</label>
                    <input
                      className="form-control"
                      type="file"
                      multiple
                      accept="image/png, image/jpeg"
                      onChange={handleFileChange}
                    />
                    <div className="mt-2">
                      <div className="d-flex img-add">
                      
                        {imagePreviews.map((preview, index) => (
                          <div key={index} className="position-relative">
                            <img
                              src={preview}
                              alt={`Preview ${index}`}
                              style={{
                                maxWidth: "100px",
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <p
                              className="clear-img"
                              onClick={() => handleRemoveImage(index)}
                            >
                              x
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6 px-4 mb-3"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="images360" className="form-label">
                      Upload 360 Image
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="images360"
                      name="images360"
                      onChange={handle360ImageChange}
                    />
                  </div>
                  <div
                    className="col-md-4 col-sm-6 px-4 mb-3"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="threeDModels" className="form-label">
                      Upload 3D Model
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="threeDModels"
                      name="threeDModels"
                      onChange={handle3DModelChange}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-accent mx-3 my-2">
                  Submit
                </button>
                <button
                  className="btn btn-secondary mx-3 my-2"
                  onClick={() => navigate(`/admin/adminPropertyListing`)}
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

    </Box>
  );
};

export default AddPropertyForm;
