// import React, { useState } from 'react';

// const AdminNavbar = () => {
//     const [dropdownOpen, setDropdownOpen] = useState(false);

//     // Fetching role and user details from local storage
//     const role = localStorage.getItem('admin') || 'Admin'; // Default role is 'Admin'
//     const user = JSON.parse(localStorage.getItem('user')) || {
//         firstName: '',
//         lastName: '',
//         phoneNumber: '',
//     };

//     const toggleDropdown = () => {
//         setDropdownOpen(!dropdownOpen);
//     };

//     const handleLogout = () => {
//         localStorage.clear();
//         window.location.reload();
//     };

//     return (
//         <nav className="navbar" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
//             <div className="container-fluid d-flex align-items-center justify-content-between">
//                 <h2 className='mb-0'>Admin</h2>
//                 <div className="d-flex align-items-center">
//                     <div className="text-end me-2">
//                         <div>{user.firstName} {user.lastName}</div>
//                         <div style={{ fontSize: '0.8rem' }}>{role}</div>
//                     </div>
//                     <div className="dropdown">
//                         <div
//                             onClick={toggleDropdown}
//                             style={{
//                                 cursor: 'pointer',
//                                 display: 'flex',
//                                 alignItems: 'center'
//                             }}
//                         >
//                             <div className="avatar-circle"
//                                 style={{
//                                     width: '40px',
//                                     height: '40px',
//                                     borderRadius: '50%',
//                                     backgroundColor: '#d6ab5e',
//                                     color: '#122620',
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                     alignItems: 'center',
//                                     marginRight: '10px',
//                                 }}>
//                                 {user.firstName.charAt(0)}{user.lastName.charAt(0)}
//                             </div>
//                         </div>
//                         {dropdownOpen && (
//                             <ul
//                                 className="dropdown-menu dropdown-menu-end show"
//                                 style={{ position: 'absolute', right: 0, marginTop: '20px', backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}
//                             >
//                                 <li className="dropdown-item" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
//                                     <strong>Name:</strong> {user.firstName} {user.lastName}
//                                 </li>
//                                 <li className="dropdown-item" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
//                                     <strong>Phone:</strong> {user.phoneNumber}
//                                 </li>
//                                 <li>
//                                     <button
//                                         className="dropdown-item"
//                                         onClick={handleLogout}
//                                         style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}
//                                     >
//                                         Logout
//                                     </button>
//                                 </li>
//                             </ul>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </nav>
//     );
// };

// export default AdminNavbar;





import React, { useState } from 'react';
import LogoutConfirmationModal from '../../../login/LogoutConfirmationModal'; // Import the Logout modal component

const AdminNavbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    // Fetching role and user details from local storage
    const role = localStorage.getItem('admin') || 'Admin'; // Default role is 'Admin'
    const user = JSON.parse(localStorage.getItem('user')) || {
        firstName: '',
        lastName: '',
        phoneNumber: '',
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const handleOpenModal = () => {
        setModalOpen(true);  // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false);  // Close the modal
    };

    const handleConfirmLogout = () => {
        handleLogout();
        handleCloseModal();  // Confirm logout and close the modal
    };

    

    return (
        <nav className="navbar" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <h2 className='mb-0'>Admin</h2>
                <div className="d-flex align-items-center">
                    <div className="text-end me-2">
                        <div>{user.firstName} {user.lastName}</div>
                        <div style={{ fontSize: '0.8rem' }}>{role}</div>
                    </div>
                    <div className="dropdown">
                        <div
                            onClick={toggleDropdown}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div className="avatar-circle"
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#d6ab5e',
                                    color: '#122620',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '10px',
                                }}>
                                {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                            </div>
                        </div>
                        {dropdownOpen && (
                            <ul
                                className="dropdown-menu dropdown-menu-end show"
                                style={{ position: 'absolute', right: 0, marginTop: '20px', backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}
                            >
                                <li className="dropdown-item" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
                                    <strong>Name:</strong> {user.firstName} {user.lastName}
                                </li>
                                <li className="dropdown-item" style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}>
                                    <strong>Phone:</strong> {user.phoneNumber}
                                </li>
                                <li>
                                    <button
                                        className="dropdown-item"
                                        onClick={handleOpenModal} // Open the logout modal
                                        style={{ backgroundColor: 'var(--bs-primary)', color: 'var(--bs-accent)' }}
                                    >
                                        Logout
                                    </button>
                                </li>
                                {/* <li className="dropdown-item" onClick={toggleDropdown}>
                                    <button className="btn-close" aria-label="Close"></button>
                                </li> */}
                            </ul>
                        )}
                    </div>
                </div>
            </div>

            {/* Logout Confirmation Modal */}
            <LogoutConfirmationModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                handleConfirmLogout={handleConfirmLogout}
            />
        </nav>
    );
};

export default AdminNavbar;

