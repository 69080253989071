import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Divider,
  Modal,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "../../../config/api";
import { useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../components/Sidebar";
import AdminNavbar from "../components/AdminNavbar";
import BathroomIcon from "@mui/icons-material/Bathtub";
import BedroomIcon from "@mui/icons-material/Bed";
import AreaIcon from "@mui/icons-material/SquareFoot";
import "./AssignLeads.css";

const AssignLeads = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [agents, setAgents] = useState([]);
  const [queries, setQueries] = useState([]);
  const [assignedQueries, setAssignedQueries] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [priority, setPriority] = useState("Warm");
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const successResponse = await ApiService.getAll("admin/employees");
        setAgents(successResponse.data);
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };

    const fetchQueries = async () => {
      try {
        const successResponse = await ApiService.getAll("admin/getQueries");
        console.log(successResponse, "getAll queries");

        if (Array.isArray(successResponse.data.data)) {
          setQueries(successResponse.data.data);
        } else {
          console.error(
            "Expected an array for queries, but got:",
            successResponse.data
          );
          setQueries([]);
        }
      } catch (error) {
        console.error("Error fetching queries:", error);
        setQueries([]);
      }
    };

    const fetchAssignedQueries = async () => {
      try {
        const successResponse = await ApiService.getAll(
          "admin/getAll-assignedQueries"
        );
        console.log(successResponse, "getAll");
        if (Array.isArray(successResponse.data.assignedQueries)) {
          setAssignedQueries(successResponse.data.assignedQueries);
          
        } else {
          console.error(
            "Expected an array for queries, but got:",
            successResponse.data
          );
          setAssignedQueries([]);
        }
      } catch (error) {
        console.error("Error fetching queries:", error);
        setQueries([]);
      }
    };
    fetchAssignedQueries();
    fetchAgents();
    fetchQueries();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDeleteQuery = (queryId) => {
    setSelectedQueries(
      selectedQueries.filter((query) => query._id !== queryId)
    );
  };

  const handleSubmit = async () => {
    const payload = {
      agent: selectedAgent,
      queryId: selectedQueries.map((query) => query._id),
      priority,
    };

    try {
      const response = await ApiService.post("admin/assignQuery", payload);
      showSnackbar("Property lead assigned successfully!", "success");

      // Clear the inputs after assigning the lead
      setSelectedAgent("");
      setSelectedQueries([]);
      setPriority("Warm");
    } catch (error) {
      showSnackbar("Error assigning property lead.", "error");
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;

    const selectedQueryObjects = queries.filter((query) =>
      value.includes(query._id)
    );

    setSelectedQueries(selectedQueryObjects);
  };

  const fetchPropertyDetails = async (propertyId) => {
    try {
      const response = await ApiService.get(`admin/property/${propertyId}`);
      setSelectedPropertyDetails(response.data.meta.property);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching property details:", error);
    }
  };

  const handleOpenModal = (propertyId) => {
    fetchPropertyDetails(propertyId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPropertyDetails(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // -----------------------------------------------------------------------------



  // ------------------------------------------------------------------------------
  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
        backgroundColor: "var(--bs-secondary)",
      }}
    >
      <Sidebar
        open={sidebarOpen}
        toggleDrawer={toggleDrawer}
        setOpen={setSidebarOpen}
      />
      <div>
        <AdminNavbar />
        <Box sx={{ p: 2, px: isSmallScreen ? 1 : 4 }}>
          {/* Assign Lead Form */}
          <div
            className="card p-2 mb-2"
            style={{
              backgroundColor: "var(--bs-primary)",
              color: "var(--bs-accent)",
              border: "2px solid var(--bs-accent)",
            }}
          >
            <Box>
              <Typography variant="h5" gutterBottom>
                Assign Property Lead to Agent
              </Typography>
              <Divider sx={{ my: 2 }} />

              <div
                className="bg-light p-2"
                style={{ backgroundColor: "var(--bs-primary)!important" }}
              >
                <Grid container spacing={2}>
                  {/* Select Agent */}
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      fullWidth
                      sx={{ borderColor: " var(--bs-accent)" }}
                    >
                      <InputLabel
                        id="agent-select-label"
                        sx={{
                          color: "var(--bs-accent)",
                        }}
                      >
                        Select Agent
                      </InputLabel>
                      <Select
                        labelId="agent-select-label"
                        value={selectedAgent}
                        onChange={(e) => setSelectedAgent(e.target.value)}
                        label="Select Agent"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "var(--bs-accent)",
                            },
                            "&:hover fieldset": {
                              borderColor: "var(--bs-accent)",
                            },
                          },
                          "& .MuiSelect-icon": {
                            color: "var(--bs-accent)",
                          },
                          color: "var(--bs-accent)",
                        }}
                      >
                        <MenuItem value="">
                          <em>--Select Agent--</em>
                        </MenuItem>
                        {agents.map((agent) => (
                          <MenuItem key={agent._id} value={agent._id}>
                            {agent.firstName} {agent.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Select Queries */}
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      fullWidth
                      sx={{ borderColor: " var(--bs-accent)" }}
                    >
                      <InputLabel
                        id="query-select-label"
                        sx={{ color: "var(--bs-accent)" }}
                      >
                        Select Queries
                      </InputLabel>
                      <Select
                        labelId="query-select-label"
                        multiple
                        value={selectedQueries.map((query) => query._id)}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select Queries" />}
                        renderValue={(selected) =>
                          selectedQueries.map((query) => query.name).join(", ")
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "var(--bs-accent)",
                            },
                            "&:hover fieldset": {
                              borderColor: "var(--bs-accent)",
                            },
                          },
                          "& .MuiSelect-icon": {
                            color: "var(--bs-accent)",
                          },
                          color: "var(--bs-accent)",
                        }}
                      >
                        {queries.length > 0 ? (
                          queries.map((query) => (
                            <MenuItem key={query._id} value={query._id}>
                              <Checkbox
                                checked={selectedQueries.some(
                                  (selected) => selected._id === query._id
                                )}
                              />
                              <ListItemText primary={query.name} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            <ListItemText
                              sx={{ color: "red" }}
                              primary="No queries available"
                            />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Select Priority */}
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      fullWidth
                      sx={{ borderColor: " var(--bs-accent)" }}
                    >
                      <InputLabel
                        // id="priority-select-label"
                        sx={{ color: "var(--bs-accent)" }}
                      >
                        Select Priority
                      </InputLabel>
                      <Select
                        labelId="priority-select-label"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                        label="Select Priority"
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "var(--bs-accent)",
                            },
                            "&:hover fieldset": {
                              borderColor: "var(--bs-accent)",
                            },
                          },
                          "& .MuiSelect-icon": {
                            color: "var(--bs-accent)",
                          },
                          color: "var(--bs-accent)",
                        }}
                      >
                        <MenuItem value="Hot">Hot</MenuItem>
                        <MenuItem value="Warm">Warm</MenuItem>
                        <MenuItem value="Cold">Cold</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>{" "}
              </div>

              <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--bs-accent)",
                    borderColor: "var(--bs-accent)",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "var(--bs-accent)",
                    },
                    alignItems: "center",
                  }}
                  onClick={handleSubmit}
                >
                  Assign Lead
                </Button>
              </Box>
            </Box>
          </div>

          {/* Display List of Assigned Queries */}

          <h5>List of Assigned Queries</h5>
          <table
            striped
            bordered
            hover
            responsive
            className="table table-striped"
            aria-label="assigned queries table"
          // className="aestyle"
          >
            <thead
              style={{
                color: "var(--bs-accent) !important",
                backgroundColor: "var(--bs-primary)!important",
                borderColor: "var(--bs-primary)!important",
              }}
            >
              <tr className="aestyle">
                <th
                  style={{
                    color: "var(--bs-accent) !important",
                    backgroundColor: "var(--bs-primary)!important",
                    borderColor: "var(--bs-primary)!important",
                    textAlign: "center",
                  }}
                >
                  Assigned to
                </th>
                <th
                  style={{
                    color: "var(--bs-accent) !important",
                    backgroundColor: "var(--bs-primary)!important",
                    borderColor: "var(--bs-primary)!important",
                    textAlign: "center",
                  }}
                >
                  Priority
                </th>
                <th
                  style={{
                    color: "var(--bs-accent) !important",
                    backgroundColor: "var(--bs-primary)!important",
                    borderColor: "var(--bs-primary)!important",
                    textAlign: "center",
                  }}
                >
                  Customer Phone
                </th>
                <th
                  style={{
                    color: "var(--bs-accent) !important",
                    backgroundColor: "var(--bs-primary)!important",
                    borderColor: "var(--bs-primary)!important",
                    textAlign: "center",
                  }}
                >
                  Property Query
                </th>
              </tr>
            </thead>
            <tbody>
              {assignedQueries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((query) => (
                  <tr key={query._id} className="aestyle">
                    <td
                      className="aestyle"
                      style={{
                        border: "1px, solid, var(--bs-primary)!important",
                        textAlign: "center",
                        textTransform: "capitalize",
                      }}
                    >
                      {query?.agent?.firstName}
                    </td>
                    <td
                      className="aestyle"
                      style={{
                        border: "1px, solid, var(--bs-primary)!important",
                        textAlign: "center",
                      }}
                    >
                      {query.priority}
                    </td>
                    <td
                      className="aestyle"
                      style={{
                        border: "1px, solid, var(--bs-primary)!important",
                        textAlign: "center",
                      }}
                    >
                      {query?.queryId?.[0]?.phone || "N/A"}
                    </td>
                    <td
                      className="aestyle"
                      style={{
                        border: "1px, solid, var(--bs-primary)!important",
                        textAlign: "center",
                      }}
                    >
                      <button
                        href="#"
                        onClick={() =>
                          handleOpenModal(query?.queryId?.[0]?.propertyId)
                        }
                        style={{
                          textDecoration: "none",
                          color: "var(--bs-accent)!important",
                          padding: "5px",
                          backgroundColor: "var(--bs-primary)!important",
                          border: "none",
                          borderRadius: "3PX",
                        }}
                      >
                        {query?.queryId?.[0]?.propertyId
                          ? "View Property"
                          : "N/A"}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <label className="me-2">Rows per page:</label>
              <select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                className="form-select form-select-sm d-inline w-auto"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </select>
            </div>
            <div>
              <button
                className="btn btn-sm btn-secondary"
                style={{
                  backgroundColor: "var(--bs-primary)!important",
                  color: "var(--bs-accent)!important",
                }}
                onClick={(e) => handleChangePage(e, page - 1)}
                disabled={page === 0}
              >
                Previous
              </button>
              <span className="mx-2">Page {page + 1}</span>
              <button
                style={{
                  backgroundColor: "var(--bs-primary)!important",
                  color: "var(--bs-accent)!important",
                }}
                className="btn btn-sm btn-secondary"
                onClick={(e) => handleChangePage(e, page + 1)}
                disabled={
                  page >= Math.ceil(assignedQueries.length / rowsPerPage) - 1
                }
              >
                Next
              </button>
            </div>
          </div>
        </Box>
      </div>
      {/* Modal to Show Property Details */}
      {/* <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "#fff",
            margin: "auto",
            width: "90%",
            height: "100%",
            mt: "10%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedPropertyDetails ? (
            <div>
              <img
                src={selectedPropertyDetails.images[0]}
                alt="Property"
                className="img-fluid mb-3"
                style={{
                  height: "50%",
                  objectFit: "cover",
                  width: "50%",
                }}
              />
              <Typography variant="h6">
                {selectedPropertyDetails.title}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Price:</strong> ${selectedPropertyDetails.price}
              </Typography>
            </div>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal> */}
      {/* <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "var(--bs-secondary)",
            margin: "auto",
            width: "100%",
            height: "auto", // Changed from "100%" to "auto" for better responsiveness
            mt: "10%",
            position: "relative",
            maxWidth: "600px", // Added maxWidth to constrain modal size on larger screens
            borderRadius: "8px", // Added border radius for better aesthetics
            boxShadow: 24, // Added shadow for depth
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 3, right: 3 }}
          >
            <CloseIcon style={{ color: "var(--bs-primary)" }} />
          </IconButton>
          {selectedPropertyDetails ? (
            <div>
              <img
                src={selectedPropertyDetails.images[0]}
                alt="Property"
                className="img-fluid mb-3"
                style={{
                  height: "auto", // Set height to auto to maintain aspect ratio
                  objectFit: "cover",
                  width: "100%", // Set width to 100% for responsiveness
                  borderRadius: "4px", // Added border radius for better aesthetics
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "var(--bs-primary)" }}
                >
                  {selectedPropertyDetails.title}
                </Typography>
                <Typography variant="h6" sx={{ color: "var(--bs-primary)" }}>
                  ₹ {selectedPropertyDetails.price}
                </Typography>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--bs-primary)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BathroomIcon sx={{ marginRight: 1 }} />
                  Bathroom: {selectedPropertyDetails.numberOfBathrooms}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--bs-primary)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BedroomIcon sx={{ marginRight: 1 }} />
                  Bedroom: {selectedPropertyDetails.numberOfBedrooms}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--bs-primary)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AreaIcon sx={{ marginRight: 1 }} />
                  Area: {selectedPropertyDetails.area}
                </Typography>
              </div>
              <Typography
                variant="body1"
                sx={{
                  color: "var(--bs-primary)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {selectedPropertyDetails.description}
              </Typography>
            </div>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal> */}


      <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 4,
            backgroundColor: "var(--bs-secondary)",
            margin: "auto",
            width: "90vw", // Set width based on viewport width
            height: "auto",
            maxWidth: "600px",
            borderRadius: "8px",
            boxShadow: 24,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "95vh", // Set max height to avoid overflow
            // overflowY: "auto", // Add scroll if content exceeds height
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ position: "absolute", top: 3, right: 3 }}
          >
            <CloseIcon style={{ color: "var(--bs-primary)" }} />
          </IconButton>
          {selectedPropertyDetails ? (
            <div>
              <div
                id="propertyCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  {selectedPropertyDetails.images.map((_, index) => (
                    <button
                      type="button"
                      key={index}
                      data-bs-target="#propertyCarousel"
                      data-bs-slide-to={index}
                      className={index === 0 ? "active" : ""}
                      aria-current={index === 0 ? "true" : "false"}
                      aria-label={`Slide ${index + 1}`}
                    ></button>
                  ))}
                </div>
                <div className="carousel-inner">
                  {selectedPropertyDetails.images.map((image, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <img
                        src={image}
                        className="d-block w-100"
                        alt={`Property ${index + 1}`}
                        style={{
                          height: "50vh", // Image height is 50% of viewport height
                          objectFit: "cover", // Maintain aspect ratio
                          width: "100%", // Take full width of carousel
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#propertyCarousel"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#propertyCarousel"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "var(--bs-primary)" }}
                >
                  {selectedPropertyDetails.title}
                </Typography>
                <Typography variant="h6" sx={{ color: "var(--bs-primary)" }}>
                  ₹ {selectedPropertyDetails.price}
                </Typography>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--bs-primary)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BathroomIcon sx={{ marginRight: 1 }} />
                  Bathroom: {selectedPropertyDetails.numberOfBathrooms}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--bs-primary)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BedroomIcon sx={{ marginRight: 1 }} />
                  Bedroom: {selectedPropertyDetails.numberOfBedrooms}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--bs-primary)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AreaIcon sx={{ marginRight: 1 }} />
                  Area: {selectedPropertyDetails.area}
                </Typography>
              </div>
              <br />
              <Typography
                variant="body1"
                sx={{
                  color: "var(--bs-primary)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {selectedPropertyDetails.description}
              </Typography>
            </div>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal>


      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignLeads;
