import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const LogoutConfirmationModal = ({ open, handleClose, handleConfirmLogout }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: 'var(--bs-secondary)', // Background color of the dialog box
          color: 'var(--bs-primary)', // Text color in the dialog
        },
      }}
    >
      <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
      <DialogContent>
        <DialogContentText id="logout-dialog-description">
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'center' }} // Aligns buttons to the right
      >
 <Button
          onClick={handleConfirmLogout}
          style={{
            backgroundColor: 'var(--bs-primary)', // Background color for the Logout button
            color: 'var(--bs-accent)', // Text color for the Logout button
            marginLeft: '10px', // Adds spacing between the two buttons
          }}
          variant="contained"
        >
          Logout
        </Button>


        <Button
          onClick={handleClose}
          style={{
            backgroundColor: 'var(--bs-accent)', // Background color for the Cancel button
            color: 'var(--bs-primary)', // Text color for the Cancel button
          }}
        >
          Cancel
        </Button>
       
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmationModal;
