
import React, { useEffect, useState } from 'react';
import { Grid,Button, Card, CardMedia, CardContent, Typography, Box, CircularProgress, IconButton } from '@mui/material';
import { CurrencyRupee as CurrencyRupeeIcon, BedOutlined as BedOutlinedIcon, BathtubOutlined as BathtubOutlinedIcon, SquareFoot as SquareFootIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../config/api'; // Adjust the import based on your project structure
import Navbar from "../../webpage/components/navbar/Navbar";
import './Wishlist.css';

const Wishlist = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await ApiService.getAll('/user/wishlist');
        setProperties(response.data.wishlist);
      } catch (err) {
        setError('Failed to fetch wishlist. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchWishlist();
  }, []);

  const handleRemoveFromWishlist = async (propertyId) => {
    try {
      const response = await ApiService.post('/user/addWishlist', { propertyId });
      if (response.status === 200) {
        setProperties((prevProperties) => prevProperties.filter((property) => property._id !== propertyId));
      }
    } catch (error) {
      console.error('Error removing from wishlist', error);
    }
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" spacing={4} marginTop={2}>
        {Array.from(new Array(3)).map((_, index) => (
          <Grid item xs={12} md={9} key={index} sx={{ marginBottom: 2 }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxShadow: 3, padding: 2, borderRadius: 3 }}>
              <CircularProgress />
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (error) {
    return (
      <div className="card m-5 p-5 h-50">
        <Typography variant="h6" align="center" sx={{ color: 'var(--bs-primary)' }}>
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div className='buyPage-background' style={{ minHeight: '100vh' }}>
      <Navbar />
      <div className='container wishlist-margin' style={{ paddingLeft: 16 }}>
        <h2 className='' style={{ marginBottom: 24, color: 'var(--bs-accent)' }}>
          Saved Properties
        </h2>
      </div>
      <Grid container justifyContent="center" spacing={4} style={{ padding: '0 16px' }}>
        {properties.length > 0 ? (
          properties.map((property) => (
            <Grid item xs={12} md={9} key={property._id} sx={{ marginBottom: 2 }}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'space-between',
                  boxShadow: 3,
                  padding: { sm: 1, md: 1, lg: 1 },
                  borderRadius: 3,
                  backgroundColor: 'var(--bs-secondary)',
                  color: 'var(--bs-primary)',
                  position: 'relative',
                  cursor: 'pointer', // Add cursor pointer
                }}
                onClick={() => navigate(`/propertyDescription/${property._id}`)} // Navigate on click
              >
                <CardMedia
                  component="img"
                  sx={{ width: { xs: '100%', md: 350 }, height: { xs: 250, sm: 260, md: 260 }, objectFit: 'cover', borderRadius: 1 }}
                  image={property.images[0]}
                  alt="No Image Available"
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: -1,
                    right: 8,
                    color: 'var(--bs-primary)',
                    '&:hover': { backgroundColor: 'var(--bs-accent)' },
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering card click
                    handleRemoveFromWishlist(property._id);
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <CardContent sx={{ flexGrow: 1, marginLeft: { xs: 0, md: 2 }, marginTop: { xs: 1, md: 3 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" component="div" >{property.title}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <CurrencyRupeeIcon sx={{}} />
                      <Typography variant="h6" sx={{ color: 'var(--bs-primary)', fontWeight: 'bold' }}>{property.price ? `${property.price.toLocaleString('en-IN')}` : "Price not available"}</Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                    {property.description || 'No description available.'}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BedOutlinedIcon sx={{ color: '#000', marginRight: 1, fontSize: '18px' }} />
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        <span className="specification">Bedrooms: </span>{property.numberOfBedrooms}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BathtubOutlinedIcon sx={{ color: 'var(--bs-primary)', marginRight: 1, fontSize: '18px' }} />
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        <span className="specification">Bathrooms: </span>{property.numberOfBathrooms}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <SquareFootIcon sx={{ color: '#000', fontSize: '18px' }} />
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        <span className="specification">Area: </span>{property.area} Sq Ft
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


                    <Button
                      variant="contained"
                      onClick={() => navigate(`/propertyDescription/${property._id}`)}

                      sx={{
                        backgroundColor: 'var(--bs-accent)',
                        '&:hover': {
                          backgroundColor: 'var(--bs-accent)'
                        },
                        color: "var(--bs-primary)",
                        fontSize: {
                          xs: '0.7rem',
                          sm: '0.8rem',
                          md: '0.8rem',
                          lg: '0.8rem',
                        },
                        padding: {
                          xs: '6px 10px',
                          sm: '8px 14px',
                          md: '8px 10px 4px 10px',
                          lg: '8px 10px 4px 10px;',
                        },
                        fontWeight: 'bold', textTransform: 'none',
                      }}
                    >
                      View Details
                    </Button>
                  </Box>

                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <div className="card m-5 p-5 h-50">
            <Typography variant="h6" align="center" sx={{ color: 'var(--bs-primary)' }}>
              No properties saved in wishlist.
            </Typography>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default Wishlist;
